.content-top-video {
	position: relative;
	line-height: 0;
	&-play {
		@include sassvg(play2, white, 100%, 100%);
		content: '';
		display: block;
		height: 80px;
		width: 80px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -40px;
		margin-left: -40px;
		transition: all .5s;
		transform: scale(0.9);
		z-index: 1;
	}
	&-image {
		position: relative;
		width: 100%;
		top: 0;
		height: 100%;
		cursor: pointer;
		background-repeat: no-repeat;
		&:before {
			content: '';
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.5);
			width: 100%;
			cursor: pointer;
		}
		&:hover {
			.content-top-video-play {
				transform: scale(1);
			}
		}
	}
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
}

.teaser-with-image {
	background-color: $box-label-light-gray;
	margin-bottom: 30px;
	padding-bottom: 0;
	position: relative;
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	.background-color-gray & {
		background-color: white; // Background is white when teaser-with-image is inside gray box
	}
	&-content {
		padding: 15px 15px 40px 15px;
		display: flex;
		width: 100%;
		flex: 1;
		flex-flow: column wrap;
		background-color: $box-label-light-gray;
		.background-color-gray & {
			background-color: white; // Background is white when teaser-with-image is inside gray box
		}

	}
	&-media {
		width: 100%;
	}
	picture {
		display: block;
		line-height: 0;
	}
	img {
		width: 100%;
		height: auto;
	}
	// Headline
	&-headline {
		@extend .h3;
		color: inherit;
		margin: 15px 0;
		font-weight: 300;
		line-height: 32px;
		word-break: break-all;
	}
	&-description {
		flex: 1;
	}
	// List
	&-list ul {
		margin-top: 0;
		margin-left: 0;
		padding-left: 0;
		li {
			color: inherit;
			padding-bottom: 20px;
			&:before {
				@include sassvg('ic-bullet-large', $icon-color);
				content: '';
				height: 20px;
				width: 30px;
				background-size: 11px auto;
				background-position: left center;
			}
		}
	}
}