// ----------------------------
// Default html sctructure:
/*
<div class="formrow-wrap">
    <label class="required" for="example">Label</label> - remove required if not needed
    <div class="field-wrap">
        <input type="text" id="example" name="example" class="form-control"  />
        <div class="errorMessage"></div>
    </div>
</div>
*/

.tx-powermail {
	background: $blue;
	padding:30px;
	h3 {
		font-size:24px;
	}
	h3,
	label,
	legend {
		color: $white;
	}
	input,
	select,
	textarea {
		background-color: #EBEFF5;
	}
}

fieldset {
	border: none;
	margin: 0 0 15px;
	padding: 0;
}

.powermail_checkbox {
	display: none;
}

label {
	display: block;
	width: $label-width;
	margin-bottom: 5px;
	margin-top:10px;
	font-size:14px;
	cursor: pointer;
	color: $label-color;
}

.select2-container {
	width:100% !important;
}

.select2-container--default .select2-selection--single {
	background-color: #EBEFF5;
    border: none;
    border-radius: 0px;
}

input, select, textarea {
	padding: $input-padding;
	border: $input-border;
	border-radius: $input-border-radius;
	background-color: $input-bckg-color;
	&,
	&::-webkit-input-placeholder {
		color: $input-placeholder-color;
		font-weight: 300;
	}
	&:focus {
		&::-webkit-input-placeholder {
			color: $input-placeholder-focus-color;
		}
	}
	&.errorClass {
		border-color: $error-color;
		&:hover {
			color: $error-hover-color;
		}
	}
	transition: all 0.3s;
}

input:focus, select:focus, textarea:focus {
	outline: none;
	border: none;
	background: #EBEFF5;
}

// Width of fields ist always 100% and defined by .powermail-field parent

.powermail_field,
.field-wrap {
	width: $input-width;
}

input, select, .select2-container {
	width: 100%;
	max-width: 100%;
}

textarea {
	width: 100%;
	max-width: 100%;
}

.errorMessage,
.powermail_message_error {
	color: $error-color;
	&:hover {
		color: $error-hover-color;
	}
	p {
		margin: 2px 0 5px;
	}
}

input[type=radio], input[type=checkbox] {
	width: auto;
}

.formrow-wrap,
.powermail_fieldwrap {
	@include flex(flex-start);
	margin-bottom: 15px;
}

label {
	&.required:after {
		content: ' *';
	}
}

legend {
	@extend h5;
	@extend .headline-accent;
	margin-bottom: 15px;
	color: $form-legend-color;
}

.page-main input[type=submit] {
	//@extend .btn-large;
	border: none !important;
	background-color: #fff;
	color: #002C7C;
	text-decoration: none;
	font-size: 14px;
	font-weight: bold;
	border-radius: 2px;
	&:focus {
		background-color: darken(#fff, 5%) !important;
		color: #002C7C !important;
		border: none !important;
	}
	margin-top: 10px;
	margin-bottom: 10px;
	width: auto;

}

// Radio and checkbox replacer
.radio-single, .checkbox-single {
	position: relative;
	padding-left: 30px;
}

.ic-checkbox, .ic-radio {
	position: absolute;
	top: 4px;
	left: 0;
	width: 20px;
	height: 20px;
	border: $input-border;
	border-radius: 2px;
	.checked & {
		background: sassvg('checkmark') no-repeat center;
		background-size: 15px;
		border: $input-border;
	}
}

.ic-radio {
	border-radius: 100%;
}

// Select

.select2-container .select2-selection--single {
	height: auto;
	padding: $input-padding;
}

.select2-container--default .select2-selection--single,
.select2-dropdown,
.select2-search__field {
	border: $input-border !important;
}

ul.select2-selection__rendered {
	li.select2-selection__choice,
	li.select2-search {
		&:before {
			content: '';
			font-size: 0;
			display: none;
			color: $input-placeholder-color;
		}
	}
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: $input-placeholder-color !important;
}

// Dropdown item selected option
.select2-container--default .select2-results__option[aria-selected=true] {
	background-color: $input-focus-background;
}

.select2-results__option,
	// Select2 dropdown li color
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	color: $input-placeholder-color !important;
}

// Dropdown arrow
.select2-selection__arrow {
	height: 100% !important;
}

.select2-selection__arrow b {
	border-color: $input-border-color transparent transparent transparent !important;
	//bottom: 0;
}

// Dropdown item hover state
.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-search__field {
	background: darken($input-focus-background, 5%);
	color: $input-placeholder-focus-color !important;
}

@include breakpoint(small only) { // Mobile only
	.powermail_field,
	.field-wrap {
		width: 100%;
	}
}

// Align button with fields for DESKTOP
@include breakpoint(large) { // Tablet landscape and above
	.page-main input[type=submit] {
		//margin-left: $label-width;
	}
}

// Form without label

form.nolabel {
	label {
		display: none;
	}

	.powermail_fieldwrap_type_check,
	.powermail_fieldwrap_type_radio {
		flex-direction: column;
		width: $label-width + $input-width;
		label {
			width: 100%;
			display: block;
		}
	}
	.powermail_field,
	.field-wrap {
		width: $label-width + $input-width;
	}
	input[type=submit] {
		margin-left: 0;
	}
}
