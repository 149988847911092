.gallery-container {
	@include flex(flex-start);
	position: relative;
	z-index: 5;
}
/*.gallery-container:not(.foot-gal){
	margin-bottom: $space-medium;
}*/

.gallery-item {
	&-5, &-4{
		&.gal{
		position: relative;
		border-bottom: 1px solid $white;
		border-right: 1px solid $white;
		}
	}
	&-5 {
		&.gal{
			&:nth-child(5n){
				border-right: none;
			}
		}
	}
	&-4 {
		&.gal{
			&:nth-child(4n){
				border-right: none;
			}
		}
	}

	&-image {
		@extend .img-responsive;
		height: 100%;
		&-cover{
			display:none;
			position: absolute;
			width:100%;
			height:100%;
			left:0;
			top:0;
			background-color: rgba(77, 77, 77, 0.7);
			text-align: center;
			a{
				font-weight: 700;
				display: table-cell;
				vertical-align: middle;
				color: $white;
			}
		}
	}
	@for $i from 2 through 12 {
		&.gallery-item-#{$i} {
			display: inline-block;
			width: calc(100%/#{$i});
		}
	}
}
.gallery-item:hover .gallery-item-image-cover{
	display: table;
}
