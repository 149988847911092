.faq-list {
	&-item {
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid $hr-border-color;
	}
	&-button {
		@include btn;
	}
}

@include accordion(
	$class: 'faq-toggle',
	$paddingTop: 15px,
	$paddingSide: 20px,
	$iconPosition: right,
	$iconColor: $primary-color,
	$fontSize: 30px,
	$lineHeight: 33px,
	$backgroundColor: $white,
	$activeBgColor: $white,
	$activeFontColor: $black,
	$activeIconColor: $primary-color
)

.faq-toggle {
	&-trigger {
		@include transition();
		border-top: 1px solid $hr-border-color;
		&:first-child {
			border: none;
		}
	}
}

.faq-images-list {
	@include flex(flex-start);
}