table,
th,
td {
	border-collapse: collapse;
	vertical-align: top;
}

table {
	width: 100%;
	margin: $table-margin;
	border-top: $table-border-top;
	border-bottom: $table-border-bottom;
	border-left: $table-border-left;
	border-right: $table-border-right;
	text-align: left;
}

th {
	padding: $th-padding;
	font-size: $th-font-size;
	font-weight: $th-font-weight;
	border-top: $th-border-top;
	border-bottom: $th-border-bottom;
	border-left: $th-border-left;
	border-right: $th-border-right;
}

td {
	padding: $td-padding;
	font-size: $td-font-size;
	font-weight: $td-font-weight;
	border-top: $td-border-top;
	border-bottom: $td-border-bottom;
	border-left: $td-border-left;
	border-right: $td-border-right;
}

caption {
	text-align: left;
	font-weight: 700;
}
.ce-table {
	// Caption
	caption {
		@extend .h5;
		@extend .headline-accent;
	}
	// Header top
	thead {
		border-bottom: 2px solid #eaddd77a;
		th {
			background-color: #eaddd77a;
			color: #AB8576;
		}
	}
	tbody {
		// Header left
		th:first-child {
			color: #AB8576;
		}
	}
	// Footer
	tfoot {
		border-top: 2px solid #eaddd77a;
		td,
		th {
			background-color: #eaddd77a;
			color: #AB8576;
		}
	}
}
// Striped table
//.ce-table-striped  {
//	td {
//		vertical-align: top;
//	}
//	tbody {
//		tr:nth-child(even) {
//			background-color: #eaddd77a;
//		}
//		td {
//			color: #595051;
//		}
//	}
//	&,
//	& th,
//	& td {
//		border: 0;
//	}
//
//}
// Table bordered
//.ce-table-bordered {
//	tr {
//		background-color: #eaddd77;
//	}
//	th {
//		color: #fff;
//	}
//}
