// DISABLE desktop items for MOBILE
// Width definitions in _l-header
@include breakpoint(narrow down) {
	.footer-container-inner{
		.c-5-3-inner, .c-5-4-inner{
			display: none;
		}
	}
}
// Sync with _m-mainnav_mob
@include breakpoint(medium down) { // Tablet landscape - 1px and below
	.header-container {
		.header-topbar-container,
		.header-logo,
		.header-login,
		.header-search,
		.header-language,
		.header-nav {
			display: none;
		}
	}
}

// DISABLE mobile header items for DESKTOP
@include breakpoint(large) { // Tablet landscape and above
	.header-logo-m,
	.header-container-m,
	.mob-menu-trigger,
	.mob-menu-trigger-label {
		display: none;
	}
}

@include breakpoint(large down) { // Tablet landscape and below
	.header-topbar,
	.header-navbar {
		width: 100%;
	}
	.page-footer{
		padding: $content-padding;
	}
}

@include breakpoint(medium down) { // Tablet landscape - 1px and below
	.header-nav {
		&-list-level-0 {
			display: block;
		}
	}

	.header-logo {
		width: 100%;
	}

	.header-login {
		width: 50%;
	}
	.header-search {
		width: 100%;
	}
	.header-language {
		width: 50%;
	}

	.page-main-container {
		.page-content-left-2 {
			width: 100%;
		}
		.page-sidebar-right-2 {
			width: 100%;
		}

		.page-sidebar-left-2-narrow {
			width: 100%;
		}
		.page-content-right-2-wide {
			width: 100%;
			.ce-uploads{
				a{
					font-size:16px;
				}
				&:hover,
				&:active,
				&:focus{
					.download-document-label{
						display: none;
					}
				}
			}

			.news-list, .event-list {
				&-item {
					display: block;
				}
				&-image {
					margin-right: 0px;
				}
			}
		}
	}

	.ship-box{

		&-text{
			width: 100% !important;
			min-width: 100% !important;
			border-bottom: 320px solid $gray-darker;
			border-left: none !important;
			position: relative;
			right: 0;

			&-inner{
				padding: 50px 20px 0 30px;
			}
			table{
				border: none;
				margin: 10px 0;
				td, th{
					color: $white;
					border:none;
					line-height: .5em;
					padding-left:0;
					padding-right: 0;
				}
				td{
					font-weight: 700;
					font-size: 14px;
				}
				th{
					font-size: 12px;
					opacity: .7;
				}
				th:not(.th-full){
					width: 55%;
				}
			}
		}
	}
	.ship-box:hover .ship-box-image-cover{
		display: table;
	}
	.ship-box:hover .ship-box-text{
		border-bottom: 320px solid $base-dark-blue-bckg;
	}
	.gallery-item.gal {
		width: 50% !important;
		border-right: 1px solid $white !important;
		&:nth-child(2n){
			border-right: none !important;
		}
	}
	.gallery-item-image{
		height:100%;
	}
	.page-wrap {
		min-width: 100%;
		min-height: 100%;
		position: relative;
		top: 0;
		bottom: 100%;
		right: 0;
		z-index: 1;
		padding: $content-padding;
	}

	.rhombus-box{
		display:block;
		position: absolute;
		top: 16px;
		right:50px;
	}

	.inner-gap-left-right {
		padding: inherit;
	}

	.page-footer {
		.inner-five-col {
			margin:0 15px;
		}

		.soc-box{
			&-inner{
				text-align:left;
			}
		}

		.footer-container-inner{
			ul{
				padding-left:0px;
			}
		}
	}
	.footer-top-container .footer-top-line p{
		font-size:15px;
	}
	.page-footer{
		padding: 0;
	}
}

@include breakpoint(large only) {
	.page-wrap {
		padding: $content-padding;
	}
}
