.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.ic-name {
  font-size: 32px;
  color: red;
}
========================================== */

.ic-images {
  width: 1.125em;
}

.ic-connection {
  width: 1.25em;
}

.ic-books {
  width: 1.125em;
}

.ic-library {
  width: 1.0625em;
}

.ic-price-tags {
  width: 1.25em;
}

.ic-history {
  width: 1.0625em;
}

.ic-keyboard {
  width: 1.125em;
}

.ic-bubbles {
  width: 1.125em;
}

.ic-bubbles2 {
  width: 1.125em;
}

.ic-bubbles3 {
  width: 1.125em;
}

.ic-bubbles4 {
  width: 1.125em;
}

.ic-users {
  width: 1.125em;
}

.ic-menu2 {
  width: 1.375em;
}

.ic-menu3 {
  width: 1.375em;
}

.ic-menu4 {
  width: 1.375em;
}

.ic-youtube2 {
  width: 2.5087890625em;
}
