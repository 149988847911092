@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list {
	background: #fff url('/assets/workfiles/project/css/vendor/slick/ajax-loader.gif') center center no-repeat;
}

/* Icons */
@font-face {
	font-family: 'slick';
	font-weight: normal;
	font-style: normal;

	src: url('/assets/workfiles/project/css/vendor/slick/fonts/slick.eot');
	src: url('/assets/workfiles/project/css/vendor/slick/fonts/slick.eot?#iefix') format('embedded-opentype'), url('/assets/workfiles/project/css/vendor/slick/fonts/slick.woff') format('woff'), url('/assets/workfiles/project/css/vendor/slick/fonts/slick.ttf') format('truetype'), url('/assets/workfiles/project/css/vendor/slick/fonts/slick.svg#slick') format('svg');
}

/* Arrows */
.slick-prev,
.slick-next {
	font-size: 0;
	line-height: 0;

	position: absolute;
	top: 50%;

	display: block;

	width: 40px;
	height: 40px;
	padding: 0;

	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);

	cursor: pointer;

	color: transparent;
	border: none;
	outline: none;
	background: $base-blue-bckg;
	z-index:100;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
	color: transparent;
	outline: none;
	//background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
	//opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
	opacity: .25;
}

.slick-prev:before,
.slick-next:before {
	font-family: 'slick';
	font-size: 20px;
	line-height: 1;

	opacity: .75;
	color: white;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-prev {
	//left: -25px;
	left: 0;
}

[dir='rtl'] .slick-prev {
	right: -25px;
	left: auto;
}

.slick-prev:before {
	content: '';
	background-image: url(/assets/img/project/arrow-left.svg);
	width: 40px;
	height: 40px;
	position: absolute;
	left:0px;
	top:0px;
}

[dir='rtl'] .slick-prev:before {
	content: '→';
}

.slick-next {
	//right: -25px;
	right: 0px;
}

[dir='rtl'] .slick-next {
	right: auto;
	left: -25px;
}

.slick-next:before {
	content: '';
	background-image: url(/assets/img/project/arrow-right.svg);
	width: 40px;
	height: 40px;
	position: absolute;
	top:0px;
	right:0px;
}

[dir='rtl'] .slick-next:before {
	content: '←';
}

/* Dots */
.slick-dotted.slick-slider {
	//margin-bottom: 30px;
}

.slick-dots {
	position: absolute;
  right:0px;
	bottom: -1px;
	//display: block;
  display:none;
	width: 100%;
	padding: 0;
	margin: 0;

	list-style: none;

	text-align: center;
	position: absolute;
	width: 100%;
	height: 50px;
	background-color: rgba(255, 255, 255, 0.7);

	&:before {
		width: 0;
		height: 0;
		border-bottom: 50px solid #fff;
		border-left: 50px solid transparent;
		position: absolute;
		bottom: 0;
		right: 0;
		content:'';
	}
}

.slick-dots li {
	position: relative;
	left:30%;
	display: inline-block;

	margin: 0 5px;
	padding: 0;

	cursor: pointer;

	&:before {
		display: none !important;
		content: '';
	}
}

.slick-dots li button {
	font-size: 0;
	line-height: 0;

	display: block;

	height: 17px;
	width: 18px;
	-webkit-transform: skew(-45deg);
	-moz-transform: skew(-45deg);
	-o-transform: skew(-45deg);
	display: inline-block;
	background-color: #00469B;

	cursor: pointer;

	color: transparent;
	border: 0;
	outline: none;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
	outline: none;
	background-color: #FABB00;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
	opacity: 1;
}

/*
.slick-dots li button:before {
	font-family: 'slick';
	font-size: 6px;
	line-height: 20px;

	position: absolute;
	top: 0;
	left: 0;

	width: 20px;
	height: 20px;

	content: '';
	text-align: center;

	opacity: .25;
	color: black;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
*/
.slick-dots li.slick-active button {
	//opacity: .75;
	//color: black;
	background-color: #FABB00;
}

// remove outline on :focus and :active
.slick-slide {
	&,
	a,
	img {
		&:active,
		&:focus {
			outline: 0;
		}
	}
}

//white line on slider bottom
.slider-bottom-cover{
  position:absolute;
  bottom:0;
  width:100%;
  height: 50px;
  background-color: rgba($white-rgb, $alpha7);
}
.rhombus-box{
  display:block;
  position: absolute;
  top: 16px;
  right:0;
}
.blue-rhombus{
  background-color: $base-blue-bckg;
}
.orange-rhombus{
  background-color: $base-yellow-bckg;
}
.blue-rhombus, .orange-rhombus{
  height: 17px;
  width: 18px;
  -webkit-transform: skew(-45deg); -moz-transform: skew(-45deg); -o-transform: skew(-45deg);
  display:inline-block;
}
.white-square{
  width: 0; height: 0;
  border-bottom: 50px solid $white;
  border-left: 50px solid transparent;
  position:absolute;
  bottom:0;
  right:0;

}
.news-teaser{
	.white-square{
		border-bottom: 25px solid $white;
  		border-left: 25px solid transparent;
	}
}
