.tab-triggers {
	@include flex(flex-start);
}

.tab {
	&-triggers {
		background-color: $gray-medium;
	}
}

.tabs-trigger-single {
	border-right: 2px solid $white;
	padding: 10px 20px;
	cursor: pointer;
	color: $white;
	&.active-tab {
		background: $primary-color;
	}
	&:hover {
		background: $primary-color;
		color: $white;
		text-decoration: none;
	}
}

.tabs-container-single {
	padding: 15px;
	.event-list {
		margin: 0;
	}
	.event-list-filters {
		display: none;
	}
}

@include accordion();

.accordion-wrap {
	.tabs-trigger-single,
	.tabs-container-single {
		display: none;
	}
}

@include breakpoint(medium) {
	.tabs-wrap {
		.accordion-trigger {
			display: none;
		}
	}
}

@include breakpoint(medium down) {
	.tabs-wrap {
		.tabs-trigger-single {
			font-size: 19px;
		}
	}
}

@include breakpoint(small only) {
	.tab-triggers {
		display: none;
	}
	.tab-containers {
		border: none;
	}
	.tabs-container-single {
		border: 1px solid $primary-color;
		border-top: none;
		margin-bottom: 20px;
		padding: 15px 10px;
	}
	.tabs-wrap {
		.accordion-trigger {
			line-height: 23px;
		}
	}
}

