body {
	// Needed for sticky footer
	/*margin: 0 0 $footer-height;*/ /* bottom = footer height */
	background-color: #fff;
	overflow-x: hidden;
}

.footer-container {
	max-width: map-get($container-width, 'large');
	@include flex();
	margin: 0 auto;
	align-items: flex-start;
	justify-content: space-between;
}

ul.breadcrumb {
	margin: 0;
	padding: 0;
    a, li{
      font-size: 11px;
	  font-weight: 400;
	  color: $gray-darker;
	  text-decoration: none;
  }
  li{
    color:$dark-blue;
    font-weight: 700;
  }
  a:hover{
	  color: $dark-blue;
  }
}

li.breadcrumb-item {
	//margin: 0 10px 0 0;
	//padding: 0 0 0 10px;
	padding: 0;
  display: inline;
	&:before {
		content: ' › ';
		margin-top: -2px;
	}
	&:first-child {
		padding-left: 0;
		&:before {
			content: none;
		}
	}
}
.image-box-wrapper{
		margin-bottom: 10px;
}
.image-box-teaser{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;


  &-headline{
    position:absolute;
    margin-bottom:0;
    text-transform: uppercase;
    z-index:55;
    a{
		text-decoration: none;
		color:$white;
		font-size:28px;
		z-index: 56;
		backface-visibility: visible;
		span{
			color:$yellow;
      }
    }
  }
  &-link.overlay{
		position: relative;
		.overlay-layer{
      content: '';
      background-color:$black;
      opacity: .5;
      width:100%;
      height:100%;
      position:absolute;
			left:0;
			top:0;
    }
	}
}

.inner-gap-left-right {
	padding: $space-left-right;
}

.contact-info-box{
	position: relative;
	margin-bottom:20px;
	.phone {
		font-size:20px;
		font-weight:700;
	}
	img{
		position: absolute;
		left:0;
		top:5px;
	}
	&-inner{
		display: inline-block;
		margin-left:40px;
		a {
			text-decoration:underline;
			&:hover{
				text-decoration: none;
			}
		}
	}
}

%side-links{
	width:100%;
	text-decoration: none;
	text-transform: uppercase;
	padding: 5px 15px;
	font-size: 14px;
	display: block;
}
.page {
	&-header{
		&-image{
			position: relative;
			img{
				width:100%;
			}
			&-white-bar{
				height: 30px;
				width: 100%;
				position: absolute;
				bottom:0;
				background-color: $white;
				opacity: .3;
			}
		}
	}
	&-main{
		&-container{
			h1{
				margin-top: 30px;
				font-size:32px;
			}
			.width-medium-contact h1{
				margin-top: 0;
				margin-bottom:60px;
			}
		}
	}
	&-sidebar {
		// Two columns
		&-left-2-narrow{
			@extend %page-column;
			width: $two-col-sidebar-narrow;
			.side-nav{
				margin-top: $space-large;
				&-list-level-0{
					margin-left: 0 !important;
					padding: 0;
				}
				&-item-level-0{
					padding: 0;
				}
				&-header{
					font-size: 24px;
					&-link{
						color: $dark-blue;
						text-decoration: none;
					}
				}

				&-link-level-0{
						 @extend %side-links;
						 color: $gray-darker;
						&-active{
							@extend %side-links;
							background-color: $dark-blue;
							color:$white;
					}
					&:hover{
						color: $dark-blue;
					}
				}
			}
			.news-categories{
				margin-top: $space-large;
				&-list-level-0{
					margin-left: 0 !important;
					padding: 0;
				}
				.news-category-item{
					padding: 0;
					&-link{
							 @extend %side-links;
							 color: $gray-darker;
							&-active{
								@extend %side-links;
								background-color: $dark-blue;
								color:$white;
						}
						&:hover{
							color: $dark-blue;
						}
					}
				}
				.news-category-heading{
					font-size: 24px;
					color: $dark-blue;
				}
			}
		}

		&-right-2 {
			.news-teaser {
				position: relative;
				padding: 20px;
				background-color: $blue;
				color:$white;
				h3{
					font-size:18px;
				}
			}
			a:not(.btn-white) {
				color:$white;
				text-decoration: underline;
				text-transform: uppercase;
				font-weight: 500;
			}
			a:not(.btn-white):hover {
				text-decoration: none;
			}
		}


	}
	// Main content
	&-content {
		&-right-2-wide {
			@extend %page-column-last;
			width: $two-col-content-wide;
			h1{
				margin-top: 30px;
				font-size:32px;
			}
			ul {
				li:not(.breadcrumb-item) {
					&:before {
						display: block;
						width: 20px;
						height: 8px;
						content: '';
						background: url('/assets/img/project/bullet-lines.png') no-repeat;
						position: absolute;
						top: 0;
						left: 0;
						margin-top: 8px;
						float: left;
						margin-left: -15px;
					}
				}
			}

			.news-list-date, .event-list-date{
				font-weight: 700;
				font-size: 14px;
				color:$headlines-color;
				opacity: .7;
				position: relative;
				top:-7px;
				font-family: $secundary-font-family;
			}
			.news-list-item{
				border-bottom: 0;
			}
			.news-list-title, .event-list-title {
				margin: 0 0 10px 0;
				a {
					text-decoration: none;
					text-transform: uppercase;
					color: $headlines-color;
				}
			}
			.news-list-descr, .event-list-details, .event-list-descr{
				font-size: 14px;
				p {
					margin-bottom: 10px;
				}
      		}
			.news-list-link{
				color:$blue;
				text-decoration: underline;
				font-weight: 700;
				font-size:12px;
				text-transform: uppercase;

			}
			.news-list-link:hover{
				text-decoration: none;
			}
			.ce-uploads{
				margin-top:$space-large;
				margin-left:0;
				padding-left:0;
				&-wrapper{
          			border-bottom: 1px solid $border-color;
         			padding: 15px 0 15px 0;
					&:hover,
					&:active,
					&:focus{
						border-bottom: 1px solid $blue;
						.download-document-label{
							display: block;
						}
						.ce-uploads-filesize{
							text-decoration: none !important;
						}
					}
					&:hover .ce-uploads-fileName,
					&:active .ce-uploads-fileName,
					&:focus .ce-uploads-fileName{
						text-decoration: underline;
					}
				}
				&-filesize{
					font-size:12px;
				}
				&-icon{
					width: 40px;
					float: left;
				}
				.ce-uploads-filesize{
					padding-left: 30px;
					text-decoration: none;
				}
				a{
					color: $dark-blue;
					font-size:24px;
					font-weight: 700;
					span{
						padding-left: 30px;
					}
				}
				li{
					padding-left: 0;
				}
				li:not(.breadcrumb-item) {
					&:before {
						content: '';
						background: none;
					}
				}
				.download-ico{
					float: right;
					margin-top: 16px;
				}
				.download-document-label{
					float:right;
					font-size: 12px;
					color: $body-text-color;
					display: none;
					margin-top: 20px;
					margin-right: 15px;
				}
			}
		}
	}
}
.team-list-member{
	@extend %clearfix;
		margin-bottom: $space-small;

	&-image{
		float: left;
		width: 25%;
	}
	&-text{
		float: left;
		width: 75%;
		padding-left: 30px;
		.member-name{
			font-size: 24px;
			color: $dark-blue;
			text-transform: uppercase;
			font-weight: 700;
			margin-bottom: 0;
			position: relative;
			top: -4px;
		}
		.member-function{
			font-size: 14px;
			font-weight: 700;
			color: $headlines-color;
			position: relative;
			top: -4px;
		}
		> p{
			margin-top: 5px;
			.member-phone{
				font-size: 16px;
				.icon{
					margin-right: 20px;
				}
			}
			.member-mail{
				font-size: 14px;
				.icon{
					margin-right: 20px;
				}
			}
		}
	}
}
.ship-box{
	width: 100%;
	position: relative;
	height: 320px;
	margin-bottom: 40px;
	overflow: hidden;
	hr{
		border-top: 1px solid $hr-border-color;
		width:80%;
	}
	&-image{
		position: relative;
		width: 70%;
		float: left;
		height: 320px;
		&-cover{
			display: none;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(77,77,77,0.7);
			text-align: center;
			a{
				color: $white;
				display: table-cell;
				vertical-align: middle;
				font-size: 16px;
				font-weight: 700;
			}
		}
	}
	&-heading{
		color: $yellow;
		font-size: 24px;
		margin-bottom: 20px;
	}
	&-text{
		width: 40%;
		min-width: 415px;
		height: 0;
		border-bottom: 320px solid $gray-darker;
		border-left: 80px solid transparent;
		position: absolute;
		right: 0;

		&-inner{
			padding: 50px 20px 0 30px;
		}
		table{
			border: none;
			margin: 10px 0;
			td, th{
				color: $white;
				border:none;
				line-height: .5em;
				padding-left:0;
				padding-right: 0;
			}
			td{
				font-weight: 700;
				font-size: 14px;
			}
			th{
				font-size: 12px;
				opacity: .7;
			}
			th:not(.th-full){
				width: 55%;
			}
		}
	}
}
.ship-box:hover .ship-box-image-cover{
	display: table;
}
.ship-box:hover .ship-box-text{
	border-bottom: 320px solid $base-dark-blue-bckg;
}
