.icon-boxes {
	max-width: map-get($container-width, large);
	margin: 0 auto;
	header {
		display: none;
	}
	&-list {
		display: flex;
		flex-flow: row wrap;
	}
	&-other {
		display: none;
		width: 100%;
		&-row {
			display: flex;
			flex-flow: row wrap;
		}
		.icon-box:first-child {
			display: none;
		}
	}
	&-toggle {
		padding:30px 0;
		width: 100%;
		&-btn {
			display: block;
			width: 24px;
			height: 20px;
			margin: 0 auto;
			background-image: $sprite;
			background-position: -150px -931px;
			cursor: pointer;
			&.open {
				background-position: -150px -881px;
			}
		}

	}
}
.icon-box {
	display: flex;
	flex-flow: column wrap;
	width: 25%;
	align-items: center;
	margin-bottom: 50px;
	padding: 0 15px;
	&-image {
		margin-bottom: 25px;
	}
	&-headline {
		font-weight: 300;
		margin-bottom: 20px;
		text-align: center;
	}
	&-description {
		flex: 1 1 auto;
		width: 100%;
		p {
			text-align: center;
			margin-bottom: 25px;
		}
	}
}

@include breakpoint(large down) {
	.icon-boxes {
		&-list {
			.icon-box:last-child {
				display: none;
			}
		}
		&-other {
			.icon-box:first-child {
				display: flex;
			}
		}
	}
	.icon-box {
		width: 33.3333%;
	}
}

@include breakpoint(small only) {
	.icon-box {
		width: 100%;
		&-headline {
			margin-bottom: 5px;
		}
		&-image {
			margin-bottom: 15px;
		}
	}
}