.header-container,
.header-container-m {
	ul, li {
		list-style: none;
		padding: 0;
		margin: 0;
	}
}

.header-logo {
	width: 280px;
	margin-top:5px;
	margin-right:20px;
	&-image {
		width: 100%;
		height: auto;
	}
}

.header-login {
	width: 200px;
	&-item {
		display: inline;
		a {
			padding:10px 15px;
		}

	}
}
.header-login-item.active{
	a{
		background-color:$dark-blue;
	}
}

.header-search {
	width: 180px;
	position: relative;
	&-form-inner {
		@include flex();
		margin:0;
	}
	&-form-input {
		font-size:12px;
		width: 180px;
		text-indent: 35px;
		padding:13px 20px;
		color: $dark-blue;
		font-weight:500;
		&.active {
			border: 1px solid #0f0;
		}
		&.valid {
			border: 1px solid #00f;
		}
		&.invalid {
			border: 1px solid #f00;
		}
	}
	&-form-input:focus{
    border:none;
	}
	&-form-input::placeholder{
    color:$input-placeholder-color;
    font-weight:700;
    font-size: 12px;
  	}
	&-submit {
		position:absolute;
		top:0;
		//left:-14px;
		width:20px;
		background-color:$nav-level-1-background;
		color:$nav-level-0-color;
		font-size: 18px;
		border:none;
		border-radius:0;
	}
	&-submit.btn-small{
	  padding: 12px 0;
	  width:46px;
	  img{
	  	position: relative;
	  	top:2px;
	  }
	}

}

.header-language {
	width: 60px;
	text-align: right;
	&-item {
		display: inline;
	}
	a {
		text-decoration: none;
	}
}

.header-social {
	&-media {
		&-list {
			width:120px;
			position: relative;
			top:2px;
		}
		&-item {
			display: inline-block;
		}
		&-item:first-of-type {
			margin-right: $space-small;
		}
	}
}
