@mixin eventList(
	$class:'event-list',
	$fullWidth: false,
	$imageWidthDesktop: 170px,
	$imageWidthTablet: $imageWidthDesktop,
	$imageHeight: $imageWidthDesktop,
	$paddingDesktop: 70px,
	$paddingTablet: 50px,
	$paddingMobile: $paddingTablet,
	$dateMargin: 0, // Date Margin bottom
	$descriptionMargin: -9px, // Description Margin Top to align Description with Image
	$containerWidth: $float-grid-container-width) {

	.#{$class} {
		margin-left: -15px;
		margin-right: -15px;
		&-item {
			@if $fullWidth {
				padding: $paddingDesktop 15px;
				display: flex;
				flex-flow: row wrap;
				border-bottom: 1px solid $event-border;
				justify-content: center;
			}
			&-content {
				flex: 1;
			}
			&:hover {
				@if $fullWidth {
					background: $event-background;
				} @else {
					.#{$class}-row {
						background: $event-background;
					}
				}
			}
		}
		&-row {
			display: flex;
			flex-flow: row wrap;
			align-items: flex-start;
			flex: 1;
			@if $fullWidth {
				max-width: $containerWidth;
				width: 100%;
				flex: 1 1 auto;
				border: none;
				padding: 0;
			} @else {
				max-width: $containerWidth;
				margin: 0 auto;
				padding: $paddingDesktop 15px;
				border-bottom: 1px solid $event-border;
			}
		}
		&-image {
			width: $imageWidthDesktop;
			height: $imageHeight;
			background: $white;
			border: 1px solid $gray-medium;
			margin-right: 30px;
			margin-bottom: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				max-width: 100%;
				max-height: 100%;
				width: auto;
				height: auto;
			}
		}
		&-date {
			font-weight: bold;
			margin-bottom: $dateMargin;
		}
		&-headline {
			line-height: 45px;
			@if $fullWidth{
				margin-bottom: 5px;
			} @else {
				margin-bottom: 15px;
			}
		}
		&subheadline {
			margin-bottom: 11px;
		}
		&-description {
			margin-top: $descriptionMargin;
		}
		&-link {
			a {
				font-weight: bold;
			}
		}
		.event-list-filters {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			max-width: $containerWidth;
			margin: 0 auto;
			padding: 0 15px;
			&-column {
				flex: 1 1 100%;
			}
		}
	}

	@include breakpoint(medium) {
		.#{$class} {
			.event-list-filters {
				&-column {
					flex: 1 1 auto;
				}
			}
		}
	}

	@include breakpoint(medium only) {
		@if $fullWidth {
			.#{$class} {
				&-image {
					width: $imageWidthTablet;
				}
			}
		}
	}

	@include breakpoint(xlarge) {
		.#{$class}  {
			&-item {
				&-content {
					display: flex;
					flex-flow: row wrap;
				}
			}
			&-description {
				flex: 1;
				padding-right: 90px;
			}
			&-link {
				flex: 0 0 auto;
				margin-bottom: 27px;
				display: flex;
				align-items: flex-end;
			}
			@if $fullWidth {
				margin: 0 -15px;
			}
			.event-list-filters {
				padding: 0;
			}
		}
	}

	@include breakpoint(medium down) {
		.#{$class}  {
			&-row {
				padding: $paddingTablet 15px;
			}
			@if $fullWidth {
				&-item {
					padding: $paddingTablet 15px;
				}
			}
		}
	}

	@include breakpoint(small only) {
		.#{$class}  {
			&-item {
				flex-flow: column wrap;
			}
			&-image {
				width: 100%;
				margin: 0 0 18px;
			}
			&-headline {
				margin-bottom: 10px;
				line-height: 35px;
			}
			&-description {
				margin-top: 0;
			}
			&-row {
				padding: $paddingMobile 15px;
			}
			@if $fullWidth {
				&-item {
					padding: $paddingMobile 15px;
				}
				&-row {
					padding: 0;
					width: 100%;
				}
				&-image {
					width: 100%;
					margin: 0 0 8px;
				}
			}
		}
	}
}