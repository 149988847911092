@mixin accordion(
	$class: 'accordion',
	$paddingTop: 10px,
	$paddingSide: 20px,
	$iconPosition: left,
	$iconColor: $primary-color,
	$fontSize: $btn-font-size,
	$lineHeight: 30px,
	$backgroundColor: $gray-news-hover,
	$activeBgColor: $primary-color,
	$activeFontColor: $white,
	$activeIconColor: $white
) {
	.#{$class}-trigger {
		display: block;
		@if $iconPosition == left {
			padding: $paddingTop $paddingSide $paddingTop 45px;
		} @else if $iconPosition == right {
			padding: $paddingTop 45px $paddingTop $paddingSide;
		} @else {
			padding: $paddingTop $paddingSide;
		}
		background-color: $backgroundColor;
		font-size: $fontSize;
		line-height: $lineHeight;
		margin: 5px 0 0;
		color: $black;
		position: relative;
		cursor: pointer;
		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 24px;
			height: 24px;
			top: calc(#{$paddingTop} + #{$lineHeight}/2 - 12px);
			@if $iconPosition == left {
				left: 15px;
			} @else if $iconPosition == right {
				right: 15px;
				left: auto;
			}
			@include sassvg(arrow-right2,$iconColor);
			background-size: 18px auto;
		}
		&:hover {
			background: $activeBgColor;
			color: $activeFontColor;
			text-decoration: none;
			&:before {
				@include sassvg(arrow-right2,$activeIconColor);
				background-size: 18px auto;
			}
		}
		&.active {
			background: $activeBgColor;
			color: $activeFontColor;
			&:before {
				@include sassvg(arrow-down2,$activeIconColor);
				background-size: 18px auto;
			}
		}
		& + div[data-trigger=content] {
			display: none;
			padding: $paddingTop $paddingSide;
			border: 1px solid $activeBgColor;
			border-top: none;
			margin-bottom: 20px;
			&:first-of-type {
				display: block;
			}
		}
	}
}