.references {
	&-box {
		@include box(null, null, null, $black);
		width: 16.6%;
		flex-direction: row;
		overflow: hidden;
		position: relative;
		padding: 0 10px;
		margin: 0 0 15px 0;
		img {
			width: 100%;
			height: auto;
		}
		&-cta {
			position: absolute;
			bottom: -100%;
			width: 100%;
			background-color: #FFCA4B;
			text-align: center;
			color: white;
			transition: all .25s;
			font-size: 14px;
			left:0;
			&-text {
				display: block;
				width: 100%;
				height: 100%;
				padding: 5px 35px 5px 20px;
				line-height: 20px;
				word-break: break-all;
			}
			&:after {
				@include sassvg(arrow-right2, white, 100%, 100%);
				content: '';
				background-size: 15px;
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 15px;
				width: 30px;
				height: 100%;
			}
			&:hover {
				&:after {
					animation-name: wobble-horizontal;
					animation-duration: 1s;
					animation-timing-function: ease-in-out;
					animation-iteration-count: 1;
				}
			}
		}
		&-content {
			position: relative;
			justify-content: center;
			align-items: center;
			width: 100%;
			border: 1px solid $box-label-light-gray;
			&:hover {
				border: 1px solid #FFCA4B;
				.references-box-cta {
					bottom: 0;
					transition: all .25s;
				}
			}
		}
		&-container {
			margin: 0 -10px;
		}

		@include breakpoint(large only) {
			padding: 0 10px;
		}

		@include breakpoint(medium down) {
			width: 33%;
			&-cta {
				&-text {
					padding: 5px 35px 5px 10px;
				}
			}
		}

		@include breakpoint(small only) {
			width: 50%;
			.references-box-cta {
				font-size: 12px;
			}
		}
	}
}
