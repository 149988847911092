// Background image settings
$slider-full-background-color-visible: true;
$slider-full-background-position: center center;

// Height of slider
$slider-full-height: 50vh;

// Loader background
$slider-full-loader-background: radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.06) 66.52%, rgba(255,255,255,0.3) 100%);

// Make footer visible on full viewport height slider
// IMPORTANT: Check values of .page-wrap ($nav-padding-main) and .header-container-m ($slideout-padding-mobile)
// and exclude them too (padding-top and padding-bottom)
$exclude-footer-height: true;

// To remove slick dot styling set $slider-full-dots var to false
//IMPORTANT you have to disable dots in slick configuration for this content element
// JS file: slider-full.js
$slider-full-dots: true;
$slider-full-dots-width: 20px;
$slider-full-dots-height: 20px;
$slider-full-dots-gutter: 5px; // margin

.slider-full {
	overflow: hidden;
	@include relative;
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;

	// Slide style
	&-item {
		@include relative;
		// Image holder element
		&-image-holder {
			position: relative;
			@include breakpoint(medium) {
				height:560px;
/*
				@if $exclude-footer-height {
					height: calc(70vh - #{$footer-height} - 40px);
				}
*/
			}
			@include breakpoint(medium down) {
				height:260px;
/*
				@if $exclude-footer-height {
					height: calc(70vh - #{$footer-height} - 40px);
				}
*/
			}
			height: $slider-full-height;
			background-size: cover;
			background-position: $slider-full-background-position;
			transition-timing-function: ease-in;
		}
		// Lazyload styling
		&-gradient-mask {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			background: $slider-full-loader-background;
			pointer-events: none;
		}
		// Content contanainer div
		&-content {
			display: none;
			@include absolute();
			.slick-current & {
				display: block;
			}
		}
		// Headline
		&-headline {}
		// Link
		&-link {
			display: block;
		}
	}
}

// Horizontal align classes
@each $h-align in left right {
	.slider-full-item-content-#{$h-align} {
		text-align: #{$h-align};
		@if $slider-full-dots {
			#{$h-align}: $slider-full-dots-width + ($slider-full-dots-gutter * 2);
		} @else {
			#{$h-align}: 0;
		}
	}
}
.slider-full-item-content-center {
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
}

// Vertical align classes
.slider-full-item-content-top {
	@if $slider-full-dots {
		top: $slider-full-dots-width + ($slider-full-dots-gutter * 2);
	} @else {
		top: 0;
	}
}
.slider-full-item-content-bottom {
	@if $slider-full-dots {
		bottom: $slider-full-dots-width + ($slider-full-dots-gutter * 2);
	} @else {
		bottom: 0;
	}
}
.slider-full-item-content-middle {
	top: 50%;
	transform: translateY(-50%);
	&.slider-full-item-content-center {
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

// Slick slider - dots
@if $slider-full-dots {
	.slider-full {
		.slick-dotted.slick-slider {
			margin-bottom: 0;
		}
		.slick-dots {
			// Reset default settings
			bottom: auto;
			margin: 0;
		}
	}
	.slick-dots {
		li {
			width: $slider-full-dots-width;
			height: $slider-full-dots-height;
			margin: $slider-full-dots-gutter;
		}
		// Dots position
		.slider-full-dots-top & {
			top: 0;
		}
		.slider-full-dots-bottom & {
			bottom: 0;
		}
		.slider-full-dots-left &,
		.slider-full-dots-right & {
			top: 0;
			width: auto;
			height: 100%;
			display: flex;
			flex-flow: column nowrap;
			// Vertical align dots
			justify-content: center;
			li {
				display: block;
			}
		}
		.slider-full-dots-right & {
			right: 0;
		}
		.slider-full-dots-left & {
			left: 0;
		}
	}
}
