// Initial image width
// This value should be set in  <flux:field.inline.fal {images}  label attribute
// path:
$logo-slider-image-width: 250px;

// To disable changing color on hover switch $showColorOnHover to false
$logo-slider-show-color-on-hover: true;

// slick settings
// If slick js settings have 'arrows' set to true, set $slick-arrow to true
$logo-slider-slick-arrow: true;
// Set arrow color
$logo-slider-slick-arrow-color: #f00;

.logo-slider {
	// container
	&-container {
		padding-top: $space-large;
		padding-bottom: $space-large;

		// If $slick-arrow is set to false, there will be no output
		@if $logo-slider-slick-arrow == true {
			.slick-arrow:before {
				// slick arrows color
				color: $logo-slider-slick-arrow-color;
			}
		}
	}

	// image
	&-image {
		@include transition();
		@extend .img-responsive;
		margin: 0 auto;
		width: $logo-slider-image-width;
		height: auto;

		@if $logo-slider-show-color-on-hover == true {
			filter: grayscale(100%);
			&:hover {
				filter: grayscale(0);
			}
		}
	}
}