
.background {
	&-dark {
		background-color: #880E4F;
	}
	&-light {
		background-color: #EFEBE9;
	}
}
//
//@include breakpoint(small only) {
//	.mobile-hide {
//		display: none;
//	}
//}
//
//@include breakpoint(large only) {
//	.tablet-hide {
//		display: none;
//	}
//}
//@include breakpoint(xlarge) {
//	.desktop-hide {
//		display: none;
//	}
//}
//
//@media print {
//	.print {
//		&-show {
//			display: block !important;
//		}
//		&-hide {
//			display: none;
//		}
//	}
//}


//body {
//	padding: 20px;
//}
//
//.page44 header h1 {
//	text-align: center;
//	font-size: 16px;
//	background: #ddd;
//	padding: 10px;
//}

/*
// --------------------------------------------------------
// Smartphone

.c-1-1-inner,
.c-2-1-inner, .c-2-2-inner,
.c-3-1-inner, .c-3-2-inner, .c-3-3-inner,
.c-4-1-inner, .c-4-2-inner, .c-4-3-inner, .c-4-4-inner,
.c-5-1-inner, .c-5-2-inner, .c-5-3-inner, .c-5-4-inner, .c-5-5-inner {
	outline: 1px solid black;
	padding: 5px;
	background-color: white;

}

body {
	background-color: beige;
}

.inner-row,
.row-c-1,
.row-c-2,
.row-c-3,
.row-c-4,
.row-c-5 {
	margin-bottom: 15px;
}

// --------------------------------------------------------
// Tablet landscape

@include breakpoint(large only) {
	body {
		background-color: skyblue;
	}
}

.page-header {
	background:#ddd;
}

.page-footer {
	background:#333;
}
*/