.news-list, .event-list {
	&-item {
		display: flex;
		padding-bottom: 30px;
		border-bottom: 1px solid $hr-border-color;
		margin-bottom: 30px;
		&-headline {
			&-link {
				color: $blue !important;
			}
		}
	}
	&-image {
		margin-right: 20px;
		margin-bottom: 10px;
	}
	&-button {
		@extend .btn-small;
		margin-bottom: 20px;
	}
	&-image {
		&-img {
			@include breakpoint(medium) {
				width: 200px !important;
				max-width: 200px !important;
			}
		}
	}
}

.news-teaser {
	&-title{
		margin-top:10px;
		margin-bottom: 10px;
	}
	&-list {
		//@include flex(flex-start);
	}
	&-item {
		margin-right: 20px;
		p{
			font-size:12px;
			margin:0 0 8px 0;
			line-height: 14px;
		}
	}
	&-image {
		margin-bottom: 10px;
	}
	&-content{
		> a{
			font-size: 12px;
		}
	}
	&-date{
		opacity: .7;
	}
	&-text{
		margin: 10px 0 8px;
	}
}

.news-detail {
	&-images {
		@include flex(flex-start);
	}
}

.news {
	&-category {
		&-item {
			margin-bottom: 10px;
			&-link {
				font-weight: 400;
				text-transform: uppercase;
			}
		}
	}
	&-category-heading {
		@extend .h4;
	}
}

.event {
	&-list {
		&-title {
			margin-bottom: 10px;
			& a {
				@extend .h3;
			}
		}
		&-subtitle {
			@extend .h5;
			@extend .headline-accent;
			text-transform: uppercase;
		}
		&-date {
			font-weight: 400;
		}
		&-details {
			font-weight: 400;
			margin-bottom: 30px;
			&-label {
				text-transform: uppercase;
				color: #AB8576;
			}
		}
	}
}
.news-detail-title {
	margin-bottom: 10px;
}
.news-detail-subtitle {
	@extend .event-list-subtitle;
}

@include breakpoint(large) {
	.news-container {
		.c-2-1-inner {
			width: 75%
		}
		.c-2-2-inner {
			width: 25%;
		}
	}
}
