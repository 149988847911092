@mixin transition($speed: 0.3s, $tproperty: ease) {
	transition: all $speed ease;
	-webkit-transition: all $speed ease;
	backface-visibility: hidden;
}

@mixin clearfix {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin inline($align: middle) {
	display: inline-block;
	vertical-align: $align;
}

@mixin flex($gutterSize: $gutter) {
	display: flex;
	flex-flow: row wrap;
	margin: 0px calc(-1 * #{$gutterSize});
}

@mixin absolute-center-content {
	transform: translate(-50%, -50%);
	position: absolute;
	top: 50%;
	left: 50%;
}

@mixin vertical-center-content {
	transform: translateY(-50%);
	position: absolute;
	top: 50%;
}

@mixin horizontal-center-content {
	transform: translateX(-50%);
	position: absolute;
	left: 50%;
}

// sourse: https://www.sitepoint.com/sass-mixins-kickstart-project/
@mixin position($position, $args) {
	@each $o in top right bottom left {
		$i: index($args, $o);
		@if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number {
			#{$o}: nth($args, $i + 1);
		}
	}
	position: $position;
}

// Positioning helpers
@mixin absolute($args: '') {
	@include position(absolute, $args);
}

@mixin fixed($args: '') {
	@include position(fixed, $args);
}

@mixin relative($args: '') {
	@include position(relative, $args);
}

@mixin transform($transform: all) {
	@if $transform == 'x' {
		transform: translateX(-50%);
	}
	@if $transform == 'y' {
		transform: translateY(-50%);
	}
	@if $transform == 'all' {
		transform: translate(-50%, -50%);
	}
}

//
@mixin box($height, $background-color, $font-size, $color) {
	display: flex;
	width: 25%;
	padding: 0 15px;
	margin-bottom: 30px;
	// Using padding-top we can give the height of the element to be equal to the width.
	&:after {
		content: '';
		display: block;
		padding-top: 100%;
	}
	&-content {
		background-color: $background-color;
		flex: 1;
		display: flex;
		flex-direction: column;
		&:hover {
			cursor: pointer;
			text-decoration: none;
		}
	}
	&-container {
		padding: 0;
		display: flex;
		flex-flow: row wrap;
		margin: 0 -10px;
	}
	&-headline {
		color: $color;
		padding: 20px;
		word-break: break-all;
	}

	@include breakpoint(large only) {
		padding: 0 10px;
		&-container {
			&-inner {
				margin: 0 90px 0 5px;
			}
		}
	}

	@include breakpoint(medium down) {
		width: 25%;
		padding: 0 8px;
		&-container {
			&-inner {
				margin: 0 -8px;
			}
		}
	}

	@include breakpoint(small only) {
		width: 50%;
		margin-bottom: 10px;
		padding: 0 10px;
		&-container {
			&-inner {
				margin: 0 -10px;
			}
		}
	}
}