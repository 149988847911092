//
// Variables
//
// You can override the default values by setting the variables in your Sass
// before importing the normalize-scss library.

@import url(https://fonts.googleapis.com/css?family=PT+Sans:400,700|Roboto+Condensed:400,700|Roboto:400,700&amp;subset=latin-ext);

// The font size set on the root html element.
$base-font-size: 16px !default;

// The base line height determines the basic unit of vertical rhythm.
$base-line-height: 24px !default;

// The length unit in which to output vertical rhythm values.
// Supported values: px, em, rem.
$base-unit: 'px' !default;

// The default font family.
$base-font-family: 'Roboto', sans-serif !default;
$heading-font-family: 'Roboto Condensed', sans-serif !default;
$secundary-font-family: 'PT Sans', sans-serif !default;

// The font sizes for h1-h6.
$h1-font-size: 48px !default;
$h1-line-height: 110% !default;

$h2-font-size: 36px !default;
$h2-line-height: 110% !default;

$h3-font-size: 24px !default;
$h3-line-height: 110% !default;

$h4-font-size: 18px !default;
$h4-line-height: 110% !default;

$h5-font-size: 16px !default;
$h5-line-height: 110% !default;

$h6-font-size: 12px !default;
$h6-line-height: 110% !default;

// The amount lists and blockquotes are indented.
$indent-amount: 20px !default;

// The following variable controls whether normalize-scss will output
// font-sizes, line-heights and block-level top/bottom margins that form a basic
// vertical rhythm on the page, which differs from the original Normalize.css.
// However, changing any of the variables above will cause
// $normalize-vertical-rhythm to be automatically set to true.
$normalize-vertical-rhythm: false !default;
