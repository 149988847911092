.jobs-box {
	@include box(null, $box-label-light-gray, null, black);
	&-content {
		display: flex;
	}
	&-headline {
		flex: 1;
		font-size: 23px;
		font-weight: 500;
		word-break: normal;
	}
	&-subheadline {
		padding: 22px 20px;
		line-height: 35px;
		word-break: break-all;
		margin-bottom: 0;
		p {
			margin-bottom: 0;
		}
	}
	&-link {
		font-weight: inherit;
		color: inherit;
		&:hover {
			color: $body-text-color;
		}
	}
}
@include breakpoint(large only) {
	.jobs-box {
		&-headline {
			font-size: 22px;
			padding: 10px;
		}
		&-subheadline {
			font-size: 22px;
			padding: 13px 10px;
		}
	}
}
@include breakpoint(medium down) {
	.jobs-box {
		&-headline {
			font-size: 16px;
			padding: 15px 10px;
		}
		&-subheadline {
			font-size: 16px;
			padding: 15px 10px;
			p {
				font-size: 16px;
			}
		}
	}
}
@include breakpoint(small down) {
	.jobs-box {
		&-headline {
			padding: 15px 10px 0;
			font-size: 15px;
			line-height: 20px;
		}
		&-subheadline {
			font-size: 15px;
			padding: 0 0 5px 10px;
			line-height: 20px;
			p {
				font-size: 15px;
			}
		}
	}
}
