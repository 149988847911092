$icon-color: $headlines-color;
.teaser-with-icon-left {
	padding: 15px;
	// Headline
	&-headline {
		//@extend .h4;
		color: inherit;
		margin-bottom: 20px;
	}
	//Link
	&-link {
		@include btn();
		text-transform: uppercase;
	}
	// List
	&-list ul {
		margin-top: 0;
		margin-left: 0;
		padding-left: 0;
		li {
			color: inherit;
			padding-bottom: 20px;
			&:before {
				@include sassvg('ic-bullet-large', $icon-color);
				content: '';
				height: 20px;
				width: 30px;
				background-size: 11px auto;
				background-position: left center;
			}
		}
	}
	// Icons
	&.icon {
		&-cart {
			padding-left: 50px;
			@include sassvg('ic-cart', $icon-color);
			background-position: 15px 16px;
			background-size: 26px auto;
		}
		&-key {
			padding-left: 50px;
			@include sassvg('key', $icon-color);
			background-position: 15px 16px;
			background-size: 26px auto;
		}
		&-search {
			padding-left: 50px;
			@include sassvg('ic-search', $icon-color);
			background-position: 15px 16px;
			background-size: 26px auto;
		}
		&-info {
			padding-left: 50px;
			@include sassvg('info', $icon-color);
			background-position: 15px 16px;
			background-size: 26px auto;
		}
	}
	// DESKTOP
	@include breakpoint(large) { // Tablet landscape and above
		.inner-four-col > div & {
			padding-right: 10px;
			// Icons
			&.icon {
				&-cart {
					padding-left: 25px;
					@include sassvg('ic-cart', $icon-color);
					background-position: 6px 25px;
					background-size: 16px 16px;
				}
				&-key {
					padding-left: 25px;
					@include sassvg('key', $icon-color);
					background-position: 6px 20px;
					background-size: 16px 16px;
				}
				&-search {
					padding-left: 25px;
					@include sassvg('ic-search', $icon-color);
					background-position: 6px 20px;
					background-size: 16px 16px;
				}
				&-info {
					padding-left: 25px;
					@include sassvg('info', $icon-color);
					background-position: 6px 20px;
					background-size: 16px 16px;
				}
			}
		}
	}
}
