.header-logo-m {
	position:relative;
	margin-left:20px;
	margin-top:5px;
}

.header-container-m {
	background: #eee;
	width: $slideout-width;
	height: 100%;
	position: fixed;
	top: 0;
	right: -$slideout-width;
	bottom: 0;
	z-index: 1;
	padding: $slideout-padding-mobile;
	overflow-y: auto;
	@include flex();
	align-content: start;
	margin:0px;
	padding-top:50px;

	.header-nav {
		width: 100%;

		// Activation on hover
		&-item-level-0 {
			.header-nav-list-level-1 { // Disables hover from desktop menu
				position: relative;
			}
/*
			&:hover {
				.header-nav-list-level-1 { // Disables hover from desktop menu
					display: none;
					// All other handling on submenu mobile is done via JS
				}
			}
*/
		}
		&-list-level-1 {
			display: block;
			background:#fff;
			padding: 0 0 0 20px !important;
		}
		&-link-level-0,
		&-link-level-0-active {
			padding: $nav-level-0-padding-mob;
		}
	
		&-link-level-1:link, 
		&-link-level-1-active:link, 
		&-link-level-1:visited, 
		&-link-level-1-active:visited {
			color: $blue;
			font-size:12px;
			a {
				padding:2px 15px !important;
			}
		}
	}
	.item-has-sub > a {
		position: relative;
	}
	
	
	.header-nav-list-toggle,
	.header-nav-list-toggle-0,
	.header-nav-list-toggle-1 {
		position: absolute;
		right: 0;
		top: 0;
		width: 50px;
		height: 100%;
		@include sassvg(arrow-nav, $body-text-color, center center, 15px 15px);
		@include transition();
		&.open {
			transform: rotate(180deg);
		}
	}

	.header-search {
		width: 100%;
		position: relative;
		&-form-inner {
			//@include flex();
			margin:inherit;
		}
		&-form-input {
		  font-size:12px;
			width: 100%;
		text-indent: 35px;
		padding:13px 20px;
		margin:0px;
		}
		&-submit {
			left:0px;
		}
	}

	.header-social {
		&-media {
			&-list {
				width:100%;
				position: relative;
				top:10px;
				text-align:center;
			}
			&-item {
				display: inline-block;
			}
			&-item:first-of-type {
				margin-right: $space-small;
			}
		}
	}

	.header-language {
		width: 100%;
		text-align: center;
		margin-bottom:10px;
		&-item {
			display: inline;
		}
	}

	.header-login {
		width: 100%;
		&-list {
			text-align: center;
			margin-top:10px;
			&-item {
				display: inline;
				a {
					padding:10px 15px;
				}

			}
		}
	}
}

// Side teaser
// Position is important for slide-in
// Moves the whole page sideways
// Hamburger
.mob-menu-trigger {
	position: absolute;
	clip: rect(0, 0, 0, 0);
}

// Format and add Animation for Hamburger (x instead of =)
.mob-menu-trigger-label {
	position: absolute;

	top: 3px;
	right: 22px;
	z-index: 65;
	width: 30px;
	height: 30px;

	transform: rotate(0deg);
	transition: .5s ease-in-out;

	cursor: pointer;

	span {
		display: block;
		position: absolute;
		height: 6px;
		width: 100%;
		background: $blue;
		border-radius: 1px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transform: rotate(0deg);
		transition: .25s ease-in-out;
	}

	span:nth-child(1) {
		top: 0px;
		transform-origin: left center;
	}

	span:nth-child(2) {
		top: 10px;
		transform-origin: left center;
	}

	span:nth-child(3) {
		top: 20px;
		transform-origin: left center;
	}

}

// Move item to the left
.mob-menu-trigger:checked + .mob-menu-trigger-label {

	// right: $slideout-width + $slideout-padding; // Icon should not move with the slider

	span:nth-child(1) {
		transform: rotate(45deg);
		top: -3px;
		left: 8px;
	}

	span:nth-child(2) {
		width: 0%;
		opacity: 0;
	}

	span:nth-child(3) {
		transform: rotate(-45deg);
		top: 18px;
		left: 8px;
	}
}

// Move page to the left
.mob-menu-trigger:checked ~ .page-wrap {
	right: $slideout-width;
}

// Move page to the left
.mob-menu-trigger:checked ~ .header-container-m {
	right: 0;
	z-index:60;
}

// Add transition
.mob-menu-trigger + label,
.page-wrap,
.header-container-m {
	transition: right 0.2s;
}
