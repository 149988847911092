@mixin headline {
	margin: 0 0 25px 0;
	color: $headlines-color;
	font-family: $base-headlines-family;
	font-weight: 700;
}

@mixin h1 {
	font-size: $h1-font-size;
	line-height: $h1-line-height;
}

@mixin h2 {
	font-size: $h2-font-size;
	line-height: $h2-line-height;
}

@mixin h3 {
	font-size: $h3-font-size;
	line-height: $h3-line-height;
}

@mixin h4 {
	font-size: $h4-font-size;
	line-height: $h4-line-height;
}

@mixin h5 {
	font-size: $h5-font-size;
	line-height: $h5-line-height;
}

