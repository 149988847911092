$background-triangle-color: #464f5b;

$light-gray: #9B9B9B;
$text-light-gray: $light-gray;
$box-label-light-gray: #DEDEDE;
$border-gray: #979797;
$accent-red: #d43c44;
$black: #000;

@mixin boxLabel(
	$bg-color: $box-label-light-gray,
	$color: $text-light-gray,
	$line-color: $bg-color,
	$padding: 7px 36px 7px 30px,
	$padding-top: 40px ) {
	padding-top: $padding-top;
	position: relative;
	overflow-x: hidden;
	border-top: 3px solid $line-color;
	.box-label-gray {
		@include  transition();
		max-width: 95%;
		display: block;
		padding: $padding;
		position: absolute;
		top: 0;
		left: -10px;
		font-size: 12px;
		line-height: 15px;
		background-color: $bg-color;
		transform: skewX(-20deg);
		color: $color;
		text-transform: uppercase;
		z-index: 3;
		&.box-label-left, &.box-label-default {left: 0;}
		&.box-label-center {right: 50%; top: 50%; transform: translate(-50%, -50%)}
		&.box-label-right {right: 0;}
		span {
			display: inline-block;
			transform: skewX(20deg);
		}
	}
}

@mixin boxLabelHover(
	$bg-color: $box-label-light-gray,
	$line-color: $bg-color,
	$color: #fff ) {
	border-top: 3px solid $line-color;
	.box-label-gray {
		background-color: $bg-color;
		color: $color;
	}
}

// mixin
.homepage-box {
	padding: 10px 10px 15px;
	min-height: 140px;
	&-container {
		margin: 15px;
		padding: 15px;
		position: relative;
		overflow: hidden;
		// Background
		&:before {
			content: '';
			position: absolute;
			right: -400px;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 9;
			background: rgba(155, 155, 155, .3);
			transform: skew(-20deg);
		}
		&.box-label-relative {
			@include boxLabel($box-label-light-gray, $text-light-gray, $border-gray);
		}
		.box-label-gray {
			 @include transition(all, .1s);
		}

		&:hover {
			@include boxLabelHover($accent-red, $border-gray);
		}
	}
	// Description
	&-description {
		@extend .p-medium;
		z-index: 10;
		position: relative;
	}
	// Headline
	&-headline {
		@extend .h3;
	}
}

