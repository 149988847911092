.header-nav {

	font-size: 15px;

	a {
		text-decoration: none;
	}

	width: 100%;

	// Complete Nav padding
	padding: $nav-padding-main;

	&-list-level-0 {
		@include flex(flex-start);
	}

	&-list-level-1 { // Hides, formats and positions subnav box
		display: none;
		position: absolute;
		min-width: $nav-popup-min-width;
		z-index: 10;
	}

	// Activation on hover
	&-item-level-0:hover {
		.header-nav-list-level-1 { // Activates subnav box
			display: block;
			margin-left: $nav-level-0-margin-side;
		}
	}

	// Nav popup formatting
	&-list-level-1 {
		background: $nav-popup-background;
		padding: 10px 0 !important;
		@if $nav-popup-border != '0' {
			border: $nav-popup-border;
		}
		@if $nav-popup-border-radius != '0' {
			border-radius: $nav-popup-border-radius;
		}
	}

	// Link formatting
	&-link-level-0 {
		@include flex();
		margin: 0 $nav-level-0-margin-side;
		padding: $nav-level-0-padding;
		background: $nav-level-0-background;
		text-transform: uppercase;

		@if $nav-level-0-border != '0' {
			border: $nav-level-0-border;
		}
		@if $nav-level-0-border-radius != '0' {
			border-radius: $nav-level-0-border-radius;
		}
		&:link,
		&:visited {
			color: $nav-level-0-color;
		}
		&:active,
		&-active {
			@extend .header-nav-link-level-0;
			color: $nav-level-0-color-active !important;
			background: $nav-level-0-background-active;
			//font-weight:700;
		}
		&:hover {
			color: $nav-level-0-color-hover;
			background: $nav-level-0-background-hover;
		}
	}
	&-link-level-1 {
		display: block;
		padding: $nav-level-1-padding;
		background: $nav-level-1-background;
		text-transform: uppercase;
		@if $nav-level-1-border != '0' {
			border: $nav-level-1-border;
		}
		@if $nav-level-1-border-radius != '0' {
			border-radius: $nav-level-1-border-radius;
		}
		&:link,
		&:visited {
			color: $nav-level-1-color;
		}
		&:active,
		&-active {
			@extend .header-nav-link-level-1;
			color: $nav-level-1-color-active !important;
			background: $nav-level-1-background-active;
		}
		&:hover {
			color: $nav-level-1-color-hover;
			background: $nav-level-1-background-hover;
		}
	}
}

.header-topbar-container {
	background: $base-blue-bckg;
	font-size:12px;
	padding:2px 0;
	overflow: hidden;

	a {
		color: #fff;

	}
	a.header-language-item-link{
		padding: 11px 2px;
		img{
			position: relative;
			top:4px;
		}
	}
	li.active a.header-language-item-link{
		background-color: $yellow;
	}
}

.header-topbar,
.header-navbar {
	width:$float-grid-container-width;
	margin:0 auto;
	display: flex;
	justify-content: flex-end;
	align-items: stretch;
}
.header-navbar {
	align-items: center;
}
