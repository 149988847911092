.image-full-width-container {
	max-width: 1440px;
	margin: 0 auto;
}
.img-full-holder {
	position: relative;
	width: 100%;
	max-width: 100%;
	background-color: lightgray;
	background-size: cover;
	background-position: center center;
}
