.cookie-notice {
	display: none;
	padding: 15px;
	background-color: rgba(68, 68, 68, 0.85);
	color: #fff;
	bottom: 0;
	position: fixed;
	right: 0;
	left: 0;
	z-index: 1030;
	text-align: center;
	p {
		margin: 10px 0;
	}
	&-container {
		display: flex;
		justify-content: space-between;
		max-width: $float-grid-container-width;
		margin: 0 auto;
		align-items: center;
	}
	&-close {
		@extend .btn-small;
		padding: 10px 20px;
		&:hover {
			color: #fff;
		}
	}
}