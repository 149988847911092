// ----------------------------
// VARIABLES

// Content widths
$container-width: (
		narrow: 640px,
		contact: 770px,
		medium: 960px,
		large: 1240px
);

$content-padding: 0 20px;

// Breakpoints
$breakpoints: (
		small: 0,
		narrow: 480px,
		medium: 768px,
		large: 1024px,
		xlarge: 1200px
);

$breakpoints-classes: (small, medium, large, xlarge);

// Grid
$gutter: 15px;
$gutterLarge: 25px;
$gutterRow: $gutter*2;
$gutterLargeRow: $gutterLarge*2;

$grid-two-cols-width: 45%;
$grid-three-cols-width: 30%;
$grid-four-cols-width: 22.5%;
$grid-five-cols-width: 18%;

$grid-two-cols-max-width: calc(50% - #{$gutterRow});
$grid-three-cols-max-width: calc(33.333% - #{$gutterRow});
$grid-four-cols-max-width: calc(25% - #{$gutterRow});
$grid-five-cols-max-width: calc(20% - #{$gutterRow});

$grid-two-cols-flex: 0 0 $grid-two-cols-max-width;
$grid-three-cols-flex: 0 0 $grid-three-cols-max-width;
$grid-four-cols-flex: 0 0 $grid-four-cols-max-width;
$grid-five-cols-flex: 0 0 $grid-five-cols-max-width;

// Float-based grid
$float-grid-container-width: map-get($container-width, large);
$float-col-gutter: 5%;
$two-col-content: 68%;
$two-col-sidebar: 100% - $two-col-content - $float-col-gutter;

$two-col-content-wide: 75%;
$two-col-sidebar-narrow: 100% - $two-col-content-wide - $float-col-gutter;

$three-col-content: 60%;
$three-col-sidebar: (100% - $three-col-content) / 2 - $float-col-gutter;

// Colors

$black: #000000;
$black-light: #292c3c;
$white: #fff;
$orange: #F57600;
$yellow: #FABB00;
$magenta: #E31B59;
$blue: #00469B;
$dark-blue:#002C7C;
$gray-dark: #67757D;
$gray-light-footer:#9F9F9F;
$gray-medium: #859197;
$gray-footer: #869299;
$gray-table: #bec5c8;
$gray: #E8EAEC;
$gray-light: #6E6E6E;
$gray-white: #F4F5F5;
$gray-news-hover: #ebedef;
$gray-darker:#4D4D4D;
$headlines-color: #4D4D4D;
$base-blue-bckg: #00469B;
//$base-blue-bckg: #084899;
$base-yellow-bckg: #FABB00;
$base-dark-blue-bckg: #002C7C;
$white-rgb: rgb(255,255,255);
$alpha7: .7;


$primary-color: $magenta;
//$headlines-color: $black-light;
$body-text-color: #4D4D4D;

//$a-link-color: darken($body-text-color, 5%);
$a-link-color: $blue;
$a-hover-active-color: darken($a-link-color, 5%);
$a-visited-color: lighten($a-link-color, 5%);

$hr-border-color: #979797;
$border-color: #D6D6D6;

$yellow-search-mark: yellow;

// Image
$image-default-border-color: #AB8576;

// The font sizes for h1-h6.
// From constants/_normalize.scss partial
$h1-font-size: 42px;
$h2-font-size: 24px;
$h3-font-size: 20px;
$h4-font-size: 16px;
$h5-font-size: 16px;

$base-headlines-family: 'Roboto Condensed', sans-serif;
$base-p-space: 20px;

$lead-font-size: 20px;
$lead-line-height: 26px;

// Lists
$ul-margin: $base-p-space 0 $base-p-space 10px;
$ol-margin: $base-p-space 0 $base-p-space 20px;

$ul-li-padding: 0 0 10px 20px;
$ol-li-padding: 0 0 10px 10px;

// Navigation
$nav-padding-main: 0; // Used as padding for main navigation

$nav-popup-min-width: 220px;
$nav-popup-background: #002C7C;
$nav-popup-border: 0;
$nav-popup-border-radius: 0;

// Nav Links definitions Level 0
$nav-level-0-padding: 32px 15px;
$nav-level-0-padding-mob: 5px 15px;
$nav-level-0-margin-side: 0;

$nav-level-0-color: #002C7C;
$nav-level-0-background: #FFF;

$nav-level-0-color-active: #FFF;
$nav-level-0-background-active: #002C7C;
$nav-level-0-color-hover: #FFF;
$nav-level-0-background-hover: #002C7C;

$nav-level-0-border: none; //1px dotted #444;
$nav-level-0-border-radius: 0;

// Nav Links definitions Level 1
$nav-level-1-padding: 5px 15px;

$nav-level-1-color: #fff;
$nav-level-1-background: transparent;

$nav-level-1-color-active: #002C7C;
$nav-level-1-background-active: #fff;

$nav-level-1-color-hover: #002C7C;
$nav-level-1-background-hover: #fff;

$nav-level-1-border: none; //1px solid #eee;
$nav-level-1-border-radius: 0;

// Mobile navigation Slideout
$slideout-padding-mobile: 20px; // Used as padding for mobile slideout
$slideout-width: 300px;
$slideout-padding: 30px;

// Table
$table-border-color: #002C7C;

$table-margin: 40px 0;
$table-border-top: 2px solid $table-border-color;
$table-border-bottom: 2px solid $table-border-color;
$table-border-left: 2px solid $table-border-color;
$table-border-right: 2px solid $table-border-color;

$th-padding: 7px 10px;
$th-font-size: 125%;
$th-font-weight: 700;
$th-border-top: 1px solid $table-border-color;
$th-border-bottom: 1px solid $table-border-color;
$th-border-left: 1px solid $table-border-color;
$th-border-right: 1px solid $table-border-color;

$td-padding: 7px 20px;
$td-font-size: inherit;
$td-font-weight: inherit;
$td-border-top: 1px solid $table-border-color;
$td-border-bottom: 1px solid $table-border-color;
$td-border-left: 1px solid $table-border-color;
$td-border-right: 1px solid $table-border-color;

// Forms
$input-border-color: rgba(0,0,0,0.5);
$input-bckg-color: #e9e9e9;
$input-focus-background: #f3f3f3;
$input-placeholder-focus-color: #444;
$input-focus-border-color: #333;
$label-color: #002C7C;
$form-legend-color: $headlines-color;
$input-placeholder-color: #a1a1a1;
$error-color: #d00;
$error-hover-color: darken($error-color, 5%);

$input-border-radius: 0px;
$input-border: none;
$input-focus-border: 1px solid $input-focus-border-color;

$input-height: 60px;
$input-width: 60%;
$textarea-width: 500px;
$label-width: 40%;

$input-padding: 10px 20px;

// Buttons
$btn-bckg-color: #002C7C;
$btn-text-color: #FFF;

$btn-border-radius: 5px;
$btn-padding: 13px 30px;

$btn-large-border-radius: 10px;
$btn-font-weight: 500;
$btn-font-size: 14px;
$btn-line-height: 100%;
$btn-large-padding: 20px 40px;
$btn-large-font-size: $lead-font-size;


// Footer
$footer-height: 100px;  /*needed for sticky footer, changes footer height and body padding bottom*/

// Spaces for grid content elements
$space-none: 0;
$space-small: 25px;
$space-medium: 40px;
$space-large: 60px;
$space-xlarge: 120px;

$space-left-right: 0 100px;

// Sprite
$sprite: url('/assets/img/project/sprite.png');

// Event List
$event-border: #ddd;
$event-background: #d3d3d3;
