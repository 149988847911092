.ce-align-left {
	text-align: left;
}

.ce-align-center {
	text-align: center;
}

.ce-align-right {
	text-align: right;
}

.ce-textpic, .ce-image, .ce-nowrap .ce-bodytext, .ce-gallery, .ce-row, .ce-uploads li, .ce-uploads div {
	overflow: hidden;
}

.ce-left .ce-gallery, .ce-column {
	float: left;
}

.ce-center .ce-outer {
	position: relative;
	float: right;
	right: 50%;
}

.ce-center .ce-inner {
	position: relative;
	float: right;
	right: -50%;
}

.ce-right .ce-gallery {
	float: right;
}

.ce-gallery figure {
	display: table;
	margin: 0;
}

.ce-gallery figcaption {
	display: table-caption;
	caption-side: bottom;
}

.ce-gallery img {
	display: block;
}

.ce-gallery iframe {
	border-width: 0;
}

.ce-border img, .ce-border iframe {
	@extend hr;
}

.ce-intext.ce-right .ce-gallery, .ce-intext.ce-left .ce-gallery, .ce-above .ce-gallery {
	margin-bottom: $base-p-space;
}

.ce-intext.ce-right .ce-gallery {
	margin-left: 10px;
}

.ce-intext.ce-left .ce-gallery {
	margin-right: 10px;
}

.ce-below .ce-gallery {
	margin-top: $base-p-space;
}

.ce-column {
	margin-right: 10px;
}

.ce-column:last-child {
	margin-right: 0;
}

.ce-row {
	margin-bottom: $base-p-space;
}

.ce-row:last-child {
	margin-bottom: 0;
}

.ce-above .ce-bodytext {
	clear: both;
}

.ce-intext.ce-left ol, .ce-intext.ce-left ul {
	padding-left: 40px;
	overflow: auto;
}

.frame-space-before-extra-small {
	margin-top: $space-none;
}

.frame-space-before-small {
	margin-top: $space-small;
}

.frame-space-before-medium {
	margin-top: $space-medium;
}

.frame-space-before-large {
	margin-top: $space-large;
}

.frame-space-before-extra-large {
	margin-top: $space-xlarge;
}

.frame-space-after-extra-small {
	margin-bottom: $space-none;
}

.frame-space-after-small {
	margin-bottom: $space-small;
}

.frame-space-after-medium {
	margin-bottom: $space-medium;
}

.frame-space-after-large {
	margin-bottom: $space-large;
}

.frame-space-after-extra-large {
	margin-bottom: $space-xlarge;
}

.frame-ruler-before:before {
	content: '';
	display: block;
	border-top: 1px solid $hr-border-color;
	margin-bottom: $base-p-space;
}

.frame-ruler-after:after {
	content: '';
	display: block;
	border-bottom: 1px solid $hr-border-color;
	margin-top: $base-p-space;
}

.frame-indent {
	margin-left: 15%;
	margin-right: 15%;
}

.frame-indent-left {
	margin-left: 33%;
}

.frame-indent-right {
	margin-right: 33%;
}