/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	overflow: hidden;
	-webkit-transform: translate3d(0, 0, 0);
}

#cboxWrapper {
	max-width: none;
}

#cboxOverlay {
	position: fixed;
	width: 100%;
	height: 100%;
}

#cboxMiddleLeft, #cboxBottomLeft {
	clear: left;
}

#cboxContent {
	position: relative;
}

#cboxLoadedContent {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

#cboxTitle {
	margin: 0;
}

#cboxLoadingOverlay, #cboxLoadingGraphic {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
	cursor: pointer;
}

.cboxPhoto {
	float: left;
	margin: auto;
	display: block;
	max-width: none;
	-ms-interpolation-mode: bicubic;
	border-radius: 4px;
	border: 1px solid #746E6F;
}

// Image gradient overlay
#cboxLoadedContent:after {
	content: '';
	display: block;
	@include absolute(top 0 right 0 bottom 28px left 0);
	border-radius: 4px;
	background: linear-gradient(180deg, rgba(27, 23, 24, 0.1) 79%, #000000 104%);
}

.cboxIframe {
	width: 100%;
	height: 100%;
	display: block;
	border: 0;
	padding: 0;
	margin: 0;
}

#colorbox, #cboxContent, #cboxLoadedContent {
	box-sizing: content-box;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay {
	background-color: rgba(27, 23, 24, 0.9);
}

#colorbox {
	outline: 0;
}

#cboxTopLeft {
	width: 21px;
	height: 21px;
}

#cboxTopRight {
	width: 21px;
	height: 21px;
}

#cboxBottomLeft {
	width: 21px;
	height: 21px;
}

#cboxBottomRight {
	width: 21px;
	height: 21px;
}

#cboxMiddleLeft {
	width: 21px;
}

#cboxMiddleRight {
	width: 21px;
}

#cboxTopCenter {
	height: 21px;
}

#cboxBottomCenter {
	height: 21px;
}

#cboxContent {
	border-radius: 4px;
	overflow: hidden;
}

.cboxIframe {
	background: #fff;
}

#cboxError {
	padding: 50px;
	border: 1px solid #ccc;
}

#cboxLoadedContent {
	margin-bottom: 28px;
	z-index: 1;

}

#cboxTitle {
	position: absolute;
	bottom: 53px;
	left: 0;
	padding-left: 40px;
	text-align: left;
	width: 100%;
	color: #fff;
	text-transform: uppercase;
	font-size: 16px;
	line-height: 22px;
}
// Black background
#cboxContent:before {
	content: '';
	@include absolute(top 0 right 0);
	width: 100%;
	z-index: -1;
	height: calc(100% - 28px);
	background-color: #000;
	border-radius: 4px;
}

#cboxCurrent {
	position: absolute;
	text-align: center;
	bottom: 53px;
	width: 150px;
	right: 55px;
	font-size: 16px;
	line-height: 22px;
	color: #fff;
	text-transform: uppercase;
}

#cboxLoadingOverlay {
	background: url(/assets/workfiles/project/css/vendor/colorbox/images/loading_background.png) no-repeat center center;
}

#cboxLoadingGraphic {
	background: url(/assets/workfiles/project/css/vendor/colorbox/images/loading.gif) no-repeat center center;
}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
	border: 0;
	padding: 0;
	margin: 0;
	overflow: visible;
	width: auto;
	background: none;
}

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {
	outline: 0;
}

#cboxSlideshow {
	position: absolute;
	bottom: 4px;
	right: 30px;
	color: #0092ef;
}

#cboxPrevious {
	position: absolute;
	bottom: 50px;
	right: 205px;
	background: url(/assets/workfiles/project/css/vendor/colorbox/images/controls.png) no-repeat -75px 0;
	background-size: 176px 58px;;
	width: 25px;
	height: 30px;
	text-indent: -9999px;
	&:hover,
	&:active,
	&:focus {
		outline: none;
	}
}

#cboxNext {
	position: absolute;
	bottom: 50px;
	right: 28px;
	background: url(/assets/workfiles/project/css/vendor/colorbox/images/controls.png) no-repeat -50px 0;
	background-size: 176px 58px;
	width: 25px;
	height: 30px;
	text-indent: -9999px;
	&:hover,
	&:active,
	&:focus {
		outline: none;
	}
}

#cboxClose {
	position: absolute;
	top: 22px;
	right: 22px;
	// Close icon is hidden until colorbox is visible
	// Prevents  white icon sliding over black background
	display: none;
	background: url(/assets/workfiles/project/css/vendor/colorbox/images/controls.png) no-repeat -25px 0;
	background-size: 176px 58px;
	width: 25px;
	height: 25px;
	text-indent: -9999px;
}

.lightbox-link:focus {
	border: none;
	outline: none;
}

/*
  The following fixes a problem where IE7 and IE8 replace a PNG's alpha transparency with a black fill
  when an alpha filter (opacity change) is set on the element or ancestor element.  This style is not applied to or needed in IE9.
  See: http://jacklmoore.com/notes/ie-transparency-problems/
*/
.cboxIE #cboxTopLeft,
.cboxIE #cboxTopCenter,
.cboxIE #cboxTopRight,
.cboxIE #cboxBottomLeft,
.cboxIE #cboxBottomCenter,
.cboxIE #cboxBottomRight,
.cboxIE #cboxMiddleLeft,
.cboxIE #cboxMiddleRight {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF, endColorstr=#00FFFFFF);
}

@include breakpoint(small only) {
	#cboxTitle {
		padding-right: 240px;
	}
	#cboxPrevious {
		left: 10px;
		right: auto;
	}
	#cboxNext {
		right: 10px;
	}
	#cboxCurrent {
		display: none !important;
	}
	#cboxTitle {
		padding: 0 35px;
	}
}