.header-container-2 {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
@include breakpoint(xlarge) {
	.header-container-2 {
		padding: 30px 120px 20px 120px;
		border-bottom: 3px solid #57585F;
		// Partial _m-mainnav l.43
		// Link formatting
		.header-nav {

			.header-nav-list-level-0 {
				justify-content: center;
			}
		&-list-toggle-0 {
			font-size: 9px;
			line-height: 18px;
			padding-left: 5px;
		}
			&-link-level-0 {
				font-size: 14px;
				line-height: 17px;
				text-align: center;
				text-transform: uppercase;
				font-weight: 400;
				font-family: 'Rubik', sans-serif;
				color: #292C3C;
				background: none;
				border: 0 !important;
				&:active,
				&-active {
					font-weight: 700;
					color: #CF064E;
					background: none;
					border: 0 !important;
				}
				&:hover {
					color: #CF064E;
					background: none;
				}
				a {
					border: 0 !important;
				}
			}

			&-link-level-1 {
				display: block;
				padding: 15px 25px;
				@if $nav-level-1-border-radius != '0' {
					border-radius: $nav-level-1-border-radius;
				}
				&:link,
				&:visited {
					color: #292C3C;
				}
				&:active,
				&-active {
					color: #CF064E;
					background: none;
				}
				&:hover {
					color: #CF064E;
					background: none;
				}
			}
		}
	}
	.header-2-logo {
		background: #57585F;
	}
	.header-2-logo,
	.header-2-buttons {
		width: 200px;
	}
	.header-2-buttons {
		@include flex;
		.header-2 {
			&-search,
			&-cart,
			&-login {
				width: 25px;
				height: 25px;
				&:hover {
					cursor: pointer;
				}
			}
			 &-search {
				@include sassvg(ic-search, #292C3C, right center, 19px 19px);
			}
			&-cart {
				@include sassvg(ic-cart, #292C3C, right center, 22px 17px);
			}
			&-login {
				 @include sassvg(ic-login, #292C3C, right center, 17px 23px);
			 }
		}
	}
}