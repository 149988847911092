// Element with picture tag as background
.responsive-image {

	// Container
	&-container {
		@include relative();
		overflow: hidden;
	}

	// Content
	&-content {
		@include absolute;
		padding: 15px;
		background: #fff;

		// Headline
		&-headline {}

		// Subheadline
		&-subheadline {}

		// Link
		&-link {}

		// Horizontal align classes style
		&.responsive-image-content-left {
			left: 0;
		}
		&.responsive-image-content-right {
			right: 0;
		}
		&.responsive-image-content-center {
			left: 50%;
			transform: translatex(-50%);
		}

		// Vertical align classes style
		&-top {
			top: 0;
			&.responsive-image-content-center {
				left: 50%;
				transform: translateX(-50%);
			}
		}
		&-middle {
			top: 50%;
			&.responsive-image-content-center {
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		&-bottom {
			bottom: 0;
		}
	}

	// Picture
	& {
		@extend .img-responsive;
	}
}