
@mixin btn(
	$bckg: $btn-bckg-color,
	$color: $btn-text-color,
	$border: $bckg,
	$btn-font-size: $btn-font-size,
	$btn-line-height: $btn-line-height
) {
	appearance: none;
	display: inline-block;
	border: 1px solid $border;
	border-radius: $btn-border-radius;
	background: $bckg;
	padding: $btn-padding;
	color:  $color;
	font-size: $btn-font-size;
	font-weight: $btn-font-weight;
	line-height: $btn-line-height;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	transition: all 0.2s;
	&:hover {
		/*background: darken($bckg, 5%);
		text-decoration: none;
		color: $color;*/
	}
	&:visited {
		color: $color;
	}
}

@mixin btn-large(
	$bckg:$btn-bckg-color,
	$color: $btn-text-color
) {
	@include btn($btn-bckg-color, $color);
	border-radius: $btn-large-border-radius;
	background: $bckg;
	padding: $btn-large-padding;
	font-size: $btn-large-font-size;
	color:  $color;
}

.btn, a.btn, input.btn {
	@include btn();
}

.btn-inverted{
	@include btn($bckg:$btn-text-color, $color:$btn-bckg-color, $border: $btn-bckg-color, $btn-font-size: 20px, $btn-line-height: 24px );
}

.btn-large, a.btn-large, input.btn-large {
	@include btn-large();
}
.btn-small {
	@include btn($btn-font-size: 16px, $btn-line-height: 20px );
}
.cta-primary {
	@include btn($btn-bckg-color, $btn-text-color);
	&-center {
		@include btn(#E31B59, #fff);
		display: table;
		margin: 0 auto;
	}
}
//custom buttons
a.btn-white, button.btn-white{
	background-color: $white;
	color:$dark-blue !important;
	text-decoration: none;
	border-radius: 0;
	font-weight: 700;
		span{
			position: relative;
			top: 3px;
	}
}