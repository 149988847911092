$content-box-font-color: #fff;

@mixin HoverBgSlide($color: #FB0752, $btnBgColor: #fff) {
	@include transition($tproperty: background-size);
	transition-timing-function: easy-in-out;
	background-color: $color;
	border: 1px solid darken($color, 2%);
	@include sassvg(box-hover, darken($color, 3%), 150% -150%, auto 100%);
	// Button
	.content-box-button {
		@include btn($btnBgColor, darken($color, 2%), $btnBgColor);
	}
	&:hover {
		background-position: right bottom;
	}
}

.content-box {
	position: relative;
	width: 100%;
	overflow: hidden;
	&-container {
		width: 100%;
		padding-top: 20px;
		padding-bottom: 70px; //link
		&-outer {
			@include flex();
		}
		&.content-box {
			&-light {
				@include HoverBgSlide(#FB0752);
			}
			&-dark {
				@include HoverBgSlide(#464f5b);
			}
		}
	}

	// Headline
	&-headline {
		@extend .h5;
		color: inherit;
		font-weight: 400;
		padding: 15px;
		margin-bottom: 0;
		position: relative;
		text-transform: uppercase;
		&:hover {
			text-decoration: none;
		}
	}

	// Description
	&-description {
		opacity: 0;
		position: relative;
		bottom: -150px;
		@include transition($tproperty: easy-in-out);
		&:hover {
			text-decoration: none;
		}
		.content-box-container:hover & {
			opacity: 1;
			height: auto;
			bottom: 0;
		}
		p {
			padding: 0 15px 15px;
		}
	}

	// Content
	&-content {
		overflow: hidden;
		@include transition();
	}

	// Button link
	&-button {
		position: absolute;
		bottom: 25px;
		right: 15px;
	}
}

// Set element font color (except button)
a.content-box-container-outer {
	&,
	&:hover,
	&:visited,
	&:link {
		color: $content-box-font-color;
	}
	&:hover {
		text-decoration: none;
	}
}