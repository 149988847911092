.page-footer {
	// Needed for sticky footer
	//position: absolute;
	position: relative;
	left: 0;
	bottom: 0;
	width: 100%;
	//height: $footer-height; // Change in constants
	background-color: $gray-light;
	margin-bottom: -100px;
	color: $white;
	.blue-back{
		background-color: $base-dark-blue-bckg;
	}
	.footer-container{
		position: relative;
		.footer-link-logo{
			img{
				width:123px;
			}
		}
		.footer-link-rhenus{
			img{
				width:170px;
			}
		}
		.upperCase{
			text-transform: uppercase;
		}
	}
	.blue-corner{
		position: absolute;
		right:0;
		top:0;
		height:100%;
	}
	.soc-box{
		position: relative;
		height:100%;
		&-inner{
			position: absolute;
			bottom: 0;
			width:100%;
			a{
				margin: 0 10px;
				img{
					height: 24px;
					fill: $gray-light-footer;
				}
			}
		}
	}
	.gallery-container{
		margin-top: $space-large;
	}
}

.footer-info-box{
	position: relative;
	margin-bottom:15px;
	img{
		position: absolute;
		left:0;
		top:5px;
	}
	&-inner{
		display: inline-block;
		margin-left: 40px;
		font-size: 14px;
	}
}

.footer-nav-item-level-0 {
	display: inline;
	padding: 5px 10px;
}
.footer-top-container{
  z-index:4;
  position: relative;
  margin-top: 80px;
  .footer-top-line{
		height:50px;
  	background-color: $base-yellow-bckg;
  	padding-top: 8px;
  	p{
  		text-align: center;
  		color: $dark-blue;
  		margin: 0;
  		font-weight: 700;
			font-size: 20px;
			img{
				width:24px;
				position: relative;
				right:15px;
				top:6px;
			}
		}
  }
}
.footer-container-inner{
	width:100%;
	position: relative;
	z-index:6;
	ul{
		margin-top:0;
		list-style: none;
		li{
			margin-bottom:15px;
			a{
				color:$white;
				text-transform:  uppercase;
				font-size: 12px;
				text-decoration: none;
			}
		}
	}
}
.marker-for-blue{
	text-transform: uppercase;
	font-size: 14px;
	img{
		margin-top:40px;
	}
}
