//$numbered-boxes-number-color: #E31B59;
.numbered-boxes {
	// container
	&-container {
		padding: 25px 0;
		&-inner {
			@extend .inner-four-col;
			max-width: map-get($container-width, large);
			margin: 0 auto;
			text-align: center;
		}
	}
	// headline
	&-headline {
		//@include h1();
		width: 100%;
		margin: 0 auto 30px;
	}
	// item
	&-item {
		@extend .c-4-1-inner;
		margin-bottom: 0;
		// number
		&-number {
			@include h5;
			@extend .headline-accent;
			//color: $numbered-boxes-number-color;
		}
		// item headline
		&-headline {
			//@extend .h4;
		}
		// description
		&-description {
			line-height: 150%;
		}
	}
}