.numbered-box {
	padding: 5px;
	// Align classes
	&-align {
		&-left {
			text-align: left;
		}
		&-center {
			text-align: center;
		}
		&-right {
			text-align: right;
		}
	}

	// Number
	&-number {
		//@include h5;
		@extend .headline-accent;
		margin-bottom: 10px;
	}

	// Headline
	&-headline {
		//@extend .h4;
	}

	// Description
	&-description {
		line-height: 150%;
	}
}