.c-1-1-inner,
.c-2-1-inner, .c-2-2-inner,
.c-3-1-inner, .c-3-2-inner, .c-3-3-inner,
.c-4-1-inner, .c-4-2-inner, .c-4-3-inner, .c-4-4-inner,
.c-5-1-inner, .c-5-2-inner, .c-5-3-inner, .c-5-4-inner, .c-5-5-inner {
	box-sizing: border-box;
	margin: 0 $gutter;
}

.inner-one-col,
.inner-two-col,
.inner-three-col,
.inner-four-col,
.inner-five-col {
	clear: both;
	@include flex();
}
.inner-two-col {
	@include flex();
}

.c-1-1-inner,
.c-2-1-inner, .c-2-2-inner,
.c-3-1-inner, .c-3-2-inner, .c-3-3-inner,
.c-4-1-inner, .c-4-2-inner, .c-4-3-inner, .c-4-4-inner,
.c-5-1-inner, .c-5-2-inner, .c-5-3-inner, .c-5-4-inner, .c-5-5-inner {
	flex: 0 0 calc(100% - #{$gutterRow});
	max-width: calc(100% - #{$gutterRow});
}

.width-medium {
	width: map-get($container-width, medium);
	margin-left: auto;
	margin-right: auto;
}



@include breakpoint(medium) {
	.width-medium-contact{
			width: map-get($container-width, contact);
			.c-2-1-inner{
				flex: 0 0 calc(42% - #{$gutterRow});
				max-width: calc(100% - #{$gutterRow});
			}
			.c-2-2-inner{
				flex: 0 0 calc(58% - #{$gutterRow});
				max-width: calc(100% - #{$gutterRow});
			}
	}

	.c-2-1-inner, .c-2-2-inner {
		flex: $grid-two-cols-flex;
		max-width: $grid-two-cols-max-width;
	}

	.c-3-1-inner, .c-3-2-inner, .c-3-3-inner {
		flex: $grid-three-cols-flex;
		max-width: $grid-three-cols-max-width;
	}

	.c-4-1-inner, .c-4-2-inner, .c-4-3-inner, .c-4-4-inner {
		flex: $grid-four-cols-flex;
		max-width: $grid-four-cols-max-width;
	}

	.c-5-1-inner, .c-5-2-inner, .c-5-3-inner, .c-5-4-inner, .c-5-5-inner {
		flex: $grid-five-cols-flex;
		max-width: $grid-five-cols-max-width;
	}

	.footer-container-inner{
		.c-5-4-inner{
			-webkit-box-flex: 0;
			-ms-flex: 0 0 calc(14.5% - 30px);
			flex: 0 0 calc(14.5% - 30px);
			max-width: calc(14.5% - 30px);
		}
		.c-5-5-inner{
			-webkit-box-flex: 0;
			-ms-flex: 0 0 calc(25.5% - 30px);
			flex: 0 0 calc(25.5% - 30px);
			max-width: calc(25.5% - 30px);
		}
	}
}




.width-narrow {
	width: map-get($container-width, narrow);
	margin-left: auto;
	margin-right: auto;
}

@include breakpoint(medium down) { // Mobile and Tablet portrait
	.width-medium {
		width: 100%;
	}
}

@include breakpoint(small only) { // Mobile
	.width-narrow {
		width: 100%;
	}
}

// Float based grid

// %clearfix placeholder class
// Clearing floats in flat-based grid
%clearfix {
	&:before,
	&:after {
		content: '';
		display: table;
		clear: both;
		width: 100%;
		height: 1px;
	}
}

// %page-column placeholder class
// Set float:left for page columns
%page-column {
	@extend %clearfix;
	float: left;
}

%page-column-last {
	@extend %clearfix;
	float: right;
}

// Container basic style
.container,
.container-fluid {
	@extend %clearfix;
}

// Container with fixed width
.container {
	max-width: $float-grid-container-width;
	margin: 0 auto;
}

.page {
	&-sidebar {
		// Two columns
		&-left-2 {
			@extend %page-column;
			width: $two-col-sidebar;
		}
		&-right-2 {
			@extend %page-column-last;
			width: $two-col-sidebar;
		}


		// Three columns
		&-left-3 {
			@extend %page-column;
			width: $three-col-sidebar;
		}
		&-right-3 {
			@extend %page-column-last;
			width: $three-col-sidebar;
		}

	}
	// Main content
	&-content {
		// Two columns
		&-left-2 {
			@extend %page-column;
			width: $two-col-content;
		}
		&-right-2 {
			@extend %page-column-last;
			width: $two-col-content;
		}
		// Three columns
		&-middle-3 {
			@extend %page-column;
			margin-left: $float-col-gutter;
			width: $three-col-content;
		}
	}
}
