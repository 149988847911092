// ----------------------------
// TAGS

html {
	color: $body-text-color;
	font-weight: 400;
}

b, strong {
    color:$blue;
}

a {
	color: $a-link-color;
	text-decoration: underline;
	@include transition;
	&:visited {
		//color: $a-link-color;
	}
	&:hover,
	&:active {
		//color: $a-hover-active-color;
		text-decoration: none;
	}
	&:hover {
		//text-decoration: underline;
	}
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid $hr-border-color;
	margin: $base-p-space 0;
}

p {
	margin: 0 0 $base-p-space 0;
}

.lead {
	font-size: $lead-font-size;
	line-height: $lead-line-height;
}

.p {
	&-large {
		font-size: 26px;
		line-height: 36px;
	}
	&-medium {
		font-size: 20px;
		line-height: 26px;
	}
	&-small {
		font-size: 14px;
		line-height: 22px;
	}
}

blockquote {
	@extend .lead;
	font-style: italic;
}

h1, .h1 {
	@include headline;
	@include h1;
}

h2, .h2 {
	@include headline;
	@include h2;
}

h3, .h3 {
	@include headline;
	@include h3;
}

h4, .h4 {
	@include headline;
	@include h4;
}

h5, .h5 {
	@include headline;
	@include h5;
}

.headline-accent {
	color: #AB8576;
	font-weight: 400;
}
span.headline-accent {
	font-size: .85em;
}

blockquote,
.quotes-wrapper {
	margin: 0 0 20px 0;
	position: relative;
	color: #292C3C;
	font-size: 20px;
	font-style: italic;
	line-height: 30px;
	&:before {
		content: '';
		position: absolute;
		display: block;
		width: 30px;
		height: 1em;
		left: -30px;
		top: 5px;
		@include sassvg(ic-quotes, #292C3C, left center, 20px 20px);
	}

	&:after {
		content: '';
		display: inline-block;
		width: 30px;
		height: 1em;
		@include sassvg(ic-quotes, #292C3C, right center, 20px 20px);
	}
	p:last-child {
		display: inline;
	}
}

// Align classes
.align-left {
	text-align: left;
}
.align-center {
	text-align: center;
}
a.align-center {
	display: block;
}
.align-right {
	text-align: right;
}
a.align-right {
	display: block;
}
.align-justify {
	text-align: justify;
}

.page-main {
	ul {
		margin: $ul-margin;
	}
	li {
		list-style: none;
		position: relative;
		padding: $ul-li-padding;
		/*&:before {
			display: block;
			width: 10px;
			height: 10px;
			content: '\2022';
			background-image: url('../../Usluge_files/bullet-lines.png');
			font-size: 15px;
			position: absolute;
			top: 0;
			left: 0;
		}*/
	}
}

li.download-list-item {
	list-style: none;
	position: relative;
	padding: $ul-li-padding;
	background: sassvg('download', $fillcolor: $a-link-color) no-repeat left 7px;
	background-size: 12px;
}

ol {
	margin: $ol-margin;
	li {
		padding: $ol-li-padding;
	}
}

b,
strong {
	font-weight: 700;
}

small {
	font-size: 80%;
}
