.tx-indexedsearch {
	&-info {
		width: auto;
		margin: 15px 0 0;
		font-size: 12px;
		line-height: 18px;
		&,
		tr,
		td,
		a {
			border: none;
			padding: 0;
			color: $gray-medium;
		}
	}
	&-redMarkup {
		background: $yellow-search-mark;
	}
	&-res {
		border-top: 1px solid $hr-border-color;
		padding: 20px 0 10px;
		h3 {
			@extend .h4;
			margin-bottom: 12px;
		}
	}
	&-searchbox-sword {
		max-width: $input-width;
		height: 56px;
		margin-right: 10px;
	}
	&-description {
		margin: 0 0 10px 0;
	}
}