@include eventList('event-image-list',$paddingMobile: 40px);
@include eventList('event-list-full',
					$fullWidth: true,
					$imageWidthDesktop: 370px,
					$imageWidthTablet: 360px,
					$imageHeight: 170px);

// SELECT 2 Overwrite
.event-list-filters {
	.select2-container {
		width: 290px !important;
	}
	.select2-container--default {
		&.select2-container--open {
			.select2-selection--single {
				.select2-selection__arrow {
					background-position: 15px -280px;
				}
			}
		}
		.select2-selection--single {
			border-color: $primary-color !important;
			.select2-selection__rendered {
				color: $primary-color !important;
			}
			.select2-selection__arrow {
				background-color: transparent;
				background-position: -35px -230px;
			}
		}
	}
}

@include breakpoint(small only) {
	.event-list-filters {
		.select2-container {
			margin-bottom: 20px;
		}
	}
}

.event-list-filter {
	&-select {
		padding: 0 !important;
		border: 1px solid $primary-color !important;
		.select2-selection__rendered {
			font-size: 17px;
			line-height: 32px;
			color: $primary-color !important;
		}
	}
	&-container {
		border: none !important;
		.select2-results {
			.select2-results__options {
				max-height: none;
				.select2-results__option {
					font-size: 17px;
					line-height: 32px;
					color: $black !important;
					background: $gray-light;
					border-bottom: 1px solid $black;
					padding: 0 10px;
					&:hover {
						color: $primary-color !important;
						background: $white;
					}
				}
			}
		}
	}
}
