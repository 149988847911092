$full-cta-phone-text-color: #AB8576;
.full-cta {
	text-align: center;
	padding-left: 15px;
	padding-right: 15px;
	margin: 30px auto;
	// Mail
	&-mail {
		&-link {
			@include btn();
		}
	}
	// Description
	&-description {
		@extend .p-medium;
		margin-top: 30px;
	}
	// Headline
	&-headline {
		@extend .h5;
		@extend .headline-accent;
	}
	&-phone {
		@include btn(#fff, $full-cta-phone-text-color, $full-cta-phone-text-color);
	}
}