html {
	// Needed for sticky footer
	position: relative;
	min-height: 100%;
}

* {
	box-sizing: border-box;
}

// Img-responsive class
.img-responsive {
	display: block;
	max-width: 100%;
	height: auto;
	vertical-align: top;
	border: none;
}

.hidden {
	display: none;
}

// Margin and padding classes
.margin-none-top {
	margin-top: $space-none;
}

.margin-small-top {
	margin-top: $space-small;
}

.margin-middle-top {
	margin-top: $space-medium;
}

.margin-large-top {
	margin-top: $space-large;
}

.margin-none-right {
	margin-right: $space-none;
}

.margin-small-right {
	margin-right: $space-small;
}

.margin-middle-right {
	margin-right: $space-medium;
}

.margin-large-right {
	margin-right: $space-large;
}

.margin-none-bottom {
	margin-bottom: $space-none;
}

.margin-small-bottom {
	margin-bottom: $space-small;
}

.margin-middle-bottom {
	margin-bottom: $space-medium;
}

.margin-large-bottom {
	margin-bottom: $space-large;
}

.margin-none-left {
	margin-left: $space-none !important;
}

.margin-small-left {
	margin-left: $space-small;
}

.margin-middle-left {
	margin-left: $space-medium;
}

.margin-large-left {
	margin-left: $space-large;
}

.padding-none-top {
	padding-top: $space-none;
}

.padding-small-top {
	padding-top: $space-small;
}

.padding-middle-top {
	padding-top: $space-medium;
}

.padding-large-top {
	padding-top: $space-large;
}

.padding-none-right {
	padding-right: $space-none;
}

.padding-small-right {
	padding-right: $space-small;
}

.padding-middle-right {
	padding-right: $space-medium;
}

.padding-large-right {
	padding-right: $space-large;
}

.padding-none-bottom {
	padding-bottom: $space-none;
}

.padding-small-bottom {
	padding-bottom: $space-small;
}

.padding-middle-bottom {
	padding-bottom: $space-medium;
}

.padding-large-bottom {
	padding-bottom: $space-large;
}

.padding-none-left {
	padding-left: $space-none;
}

.padding-small-left {
	padding-left: $space-small;
}

.padding-middle-left {
	padding-left: $space-medium;
}

.padding-large-left {
	padding-left: $space-large;
}

// Text alignment classes 
.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

// Reset normalize

.ce-border img {
	height: auto;
	border: 1px solid $image-default-border-color;
}

// Visibility classes
// CE b-end

@include breakpoint(small only) {
	.mobile-hide {
		display: none;
	}
}

@include breakpoint(large only) {
	.tablet-hide {
		display: none;
	}
}
@include breakpoint(xlarge) {
	.desktop-hide {
		display: none;
	}
}

@media print {
	.print {
		&-show {
			display: block !important;
		}
		&-hide {
			display: none;
		}
	}
}