.google-maps {
	&--image {
		margin-bottom: 5px;
		margin-top: 15px;
		width: auto;
		max-width: 100% !important;
	}
	&--headline {
		margin-bottom: 5px;
		padding-right: 15px;
	}
	&--phone {
		margin-bottom: 5px;
		padding-right: 15px;
	}
	&--fax {
		margin-bottom: 5px;
	}
	&--website {
		margin-bottom: 5px;
	}
	&--mail {
		margin-bottom: 5px;
	}
}